import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

function PageWrapper(props) {
  return (
    <div>
      <Header />
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>{props.pageTitle}</li>
            </ol>
            <h2>{props.pageTitle}</h2>
          </div>
        </section>
        <section className="inner-page">
          <div className="container" data-aos="fade-up">
            {props.children}
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default PageWrapper;
