import React, { useState } from "react";
import Terms from "../Components/Terms";
import PhoneInput from "react-phone-number-input";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/Logo/ene logo-1.png";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../store/actions/authActions";
import { clearNotifications } from "../store/actions/notificationsActions";
import { toast, ToastContainer } from "react-toastify";

import "react-phone-number-input/style.css";

export default function Register() {
	const [inputValue, setInputValue] = useState({
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
		password: "",
		confirmPassword: "",
	});

	const [phoneVal, setPhoneValue] = useState();

	const notification = useSelector((state) => state.notification);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleChange = (event) => {
		const { name, value } = event.target;

		setInputValue((values) => ({ ...values, [name]: value }));
	};

	React.useEffect(() => {
		if (notification?.errors?.message) {
			const { message } = notification?.errors;
			toast.error(message);
			dispatch(clearNotifications());
		}
	}, [dispatch, notification]);

	const handleSubmit = (event) => {
		event.preventDefault();
		inputValue.phone = phoneVal;
		dispatch(signup(inputValue, navigate));
	};

	return (
		<div>
			<Container>
				<Row className='vh-100 d-flex justify-content-center align-items-center text-dark'>
					<Col sm={7} md={7} lg={6} xl={6} xs={12}>
						<Card className='shadow'>
							<Card.Body>
								<div className='mb-3 mt-md-4'>
									<div className='text-center'>
										<img src={Logo} className='w-50' alt='eneconomics logo' />
										<h3 className='mt-3 mb-4 text-dark'>Create an Account</h3>
									</div>
									<div className='mb-3'>
										<Form onSubmit={handleSubmit}>
											<Form.Group
												className='mb-3'
												controlId='validationCustom01'>
												<Form.Label>First Name</Form.Label>
												<Form.Control
													type='text'
													onChange={handleChange}
													placeholder='Enter firstname'
													name='firstname'
													value={inputValue.firstname}
													required
												/>
											</Form.Group>

											<Form.Group
												className='mb-3'
												controlId='validationCustom02'>
												<Form.Label>Last Name</Form.Label>
												<Form.Control
													type='text'
													onChange={handleChange}
													placeholder='Enter lastname'
													name='lastname'
													value={inputValue.lastname}
													required
												/>
											</Form.Group>

											<Form.Group className='mb-3' controlId='formBasicEmail'>
												<Form.Label>Email</Form.Label>
												<Form.Control
													type='email'
													onChange={handleChange}
													placeholder='Enter email'
													name='email'
													value={inputValue.email}
													required
												/>
											</Form.Group>

											{/* <Form.Group
												className='mb-3'
												controlId='validationCustom04'>
												<Form.Label>Phone Number</Form.Label>
												<Form.Control
													type='tel'
													onChange={handleChange}
													placeholder='Enter phone number'
													name='phone'
													value={inputValue.phone}
													required
												/>
											</Form.Group> */}
											<Form.Group
												className='mb-3'
												controlId='validationCustom04'>
												<Form.Label>Phone Number</Form.Label>
												<PhoneInput
													placeholder='Enter phone number'
													value={phoneVal}
													onChange={setPhoneValue}
												/>
											</Form.Group>

											<Form.Group
												className='mb-3'
												controlId='formBasicPassword'>
												<Form.Label>Password</Form.Label>
												<Form.Control
													type='password'
													onChange={handleChange}
													placeholder='Password'
													name='password'
													value={inputValue.password}
													required
												/>
											</Form.Group>

											<Form.Group
												className='mb-3'
												controlId='formBasicPasswordconfirm'>
												<Form.Label>Confirm Password</Form.Label>
												<Form.Control
													type='password'
													onChange={handleChange}
													placeholder='Confirm Password'
													name='confirmPassword'
													value={inputValue.confirmPassword}
													required
												/>
											</Form.Group>
											<Form.Group
												className='mb-3'
												controlId='formBasicCheckbox'>
												<Form.Check
													required
													label='Agree to terms and conditions'
													feedback='You must agree before submitting.'
													feedbackType='invalid'
													data-bs-toggle='modal'
													data-bs-target='#terms-modal'
													onChange={(e) =>
														e.target.checked
															? setInputValue({ ...inputValue, terms: true })
															: setInputValue({ ...inputValue, terms: false })
													}
												/>
											</Form.Group>

											{/* <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox1"
                      >
                        <Form.Check
                          required
                          label="I'm not a robot"
                          feedback="confirm that you are not a robot."
                          feedbackType="invalid"
                          onChange={(e) =>
                            e.target.checked
                              ? setInputValue({ ...inputValue, terms: true })
                              : setInputValue({ ...inputValue, terms: false })
                          }
                        />
                      </Form.Group> */}

											<div className='d-grid'>
												<Button variant='primary' type='submit'>
													Register
												</Button>
											</div>
										</Form>
										<div className='mt-3'>
											<p className='mb-0  text-center'>
												Already have an account?{" "}
												<Link to='/login' className='text-primary fw-bold'>
													Login
												</Link>
											</p>
										</div>
									</div>
									<Terms />
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			<ToastContainer position='top-left' />
		</div>
	);
}
