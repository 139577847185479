import React from "react";

function ServicesSection() {
  return (
    <section id="services" className="services section-bg ">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Services</h2>
          <p>
            Our Core activities include Civic Engagements, Youth Empowerment,
            and Consultancy Services (research and business).
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
              <i className="bi bi-briefcase" />
              <h4>
                <a href="#">Commission and fund research</a>
              </h4>
              <p>
                Commission and fund research that are solution-driven and
                development-focused for policymaking purposes
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
              <i className="bi bi-bar-chart" />
              <h4>
                <a href="#"> Business Clinic</a>
              </h4>
              <p>
                Business Clinic to mentor and coach MSMEs, as well as provide
                support for entrepreneurship development
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
              <i className="bi bi-card-checklist" />
              <h4>
                <a href="#">Eneconomics Consultative Forum</a>
              </h4>
              <p>
                Eneconomics Consultative Forum designed to nurture a National
                and Global Community of Young Professionals, Influencers, and
                Policy Makers to develop, incubate, and share indigenous ideas
                for sustainable development of developing countries.
              </p>
            </div>
          </div>
          
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay={400}>
              <i className="bi bi-binoculars" />
              <h4>
                <a href="#">Host Events</a>
              </h4>
              <p>
                Host Events that brings together the youth community (young
                professionals, students, influencers, and policymakers) from all
                discipline to brainstorm, develop and lead indigenous ideas for
                Sustainable Development. These events are in form of symposiums,
                workshops, seminars, conferences, and summits
              </p>
            </div>
          </div>
          {/* <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay={500}>
              <i className="bi bi-brightness-high" />
              <h4>
                <a href="#">Magni Dolore</a>
              </h4>
              <p>
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum deleniti atque
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay={600}>
              <i className="bi bi-calendar4-week" />
              <h4>
                <a href="#">Eiusmod Tempor</a>
              </h4>
              <p>
                Et harum quidem rerum facilis est et expedita distinctio. Nam
                libero tempore, cum soluta nobis est eligendi
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
