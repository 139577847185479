import axios from "axios";

let baseURL;

// https://eneconomics-api.onrender.com/api/v1
// http://localhost:4000/api/v1

if (process.env.NODE_ENV === "production") {
	baseURL = "https://eneconomics-api.onrender.com/api/v1";
} else {
	baseURL = "https://eneconomics-api.onrender.com/api/v1";
}

const API = axios.create({ baseURL });

API.interceptors.request.use((req) => {
	if (localStorage.getItem("profile")) {
		req.headers.Authorization = `Bearer ${
			JSON.parse(localStorage.getItem("profile")).token
		}`;
	}

	return req;
});

export const signIn = (formData) => API.post("/auth/signin", formData);
export const signUp = (formData) => API.post("/auth/signup", formData);
export const forgotpassword = (email) =>
	API.post("/auth/forgotpassword", email);
export const resetpassword = (formdata) =>
	API.patch("/auth/resetpassword", formdata);
export const checklink = (token) => API.get(`/auth/checklink/${token}`);
export const eventRegister = (formData) =>
	API.post(`/user/event-register`, formData);
export const eventPayment = (formData) =>
	API.post(`/user/event-payment`, formData);
export const getEvents = () => API.get(`/user/my-events`);
export const profileUpdate = (formData) =>
	API.patch(`/user/profileupdate`, formData);
export const imageUpdate = (formData) =>
	API.patch(`/user/profileimageupdate`, formData);
export const verifyAccount = (token) =>
	API.get(`/auth/verify_account/${token}`);
