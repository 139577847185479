import React from "react";
import PageWrapper from "./PageWrapper";

function AboutPage() {
  return (
    <PageWrapper pageTitle="About Us">
      <div className="row">
        <div className="col-sm-12 col-md-4">
          <h6>Our Key Area of Focus</h6>
          <p className="text-justify">
            Through increased advocacy for a sustainable and economically
            empowered Nation, we work with the Youth Community (Young
            Professionals, Influencers, Researchers/Scholars, and Policymakers)
            and focus on three pillars:{" "}
          </p>
          <ul>
            <li>Development-focused research, </li>
            <li>Youth Empowerment (leadership and business), and </li>
            <li>Civic Engagements</li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-4">
          <h6>VISION</h6>
          <p className="text-justify">
            To become a leading global youth hub for developing and leading
            indigenous solutions, a think-tank for building a sustainable and
            economically empowered Nation.
          </p>
        </div>
        <div className="col-sm-12 col-md-4">
          <h6>MISSION</h6>
          <p className="text-justify">
            To engage the Youth Community in brainstorming, innovating, and
            leading indigenous ideas for a sustainable economically empowered
            Country{" "}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h6>What we do</h6>
          <p>
            Our Core activities include Civic Engagements, Youth Empowerment, and
            Consultancy Services (research and business).
          </p>{" "}
          <br />
          <ul>
            <li>
              <span className="text-decoration-underline">
                Commission and fund research{" "}
              </span>{" "}
              that are solution-driven and development-focused for policymaking
              purposes
            </li>{" "}
            <br />
            <li>
              <span className="text-decoration-underline">
                Eneconomics Consultative Forum{" "}
              </span>{" "}
              designed to nurture a National and Global Community of Young
              Professionals, Influencers, and Policy Makers to develop, incubate,
              and share indigenous ideas for sustainable development of developing
              countries.
            </li>{" "}
            <br />
            <li>
              <span className="text-decoration-underline">Business Clinic </span> to
              mentor and coach MSMEs, as well as provide support for
              entrepreneurship development
            </li>{" "}
            <br />
            <li>
              <span className="text-decoration-underline">Host Events </span> that
              brings together the youth community (young professionals, students,
              influencers, and policymakers) from all discipline to brainstorm,
              develop and lead indigenous ideas for Sustainable Development. These
              events are in form of symposiums, workshops, seminars, conferences,
              and summits
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <img src="assets/images/someimages/about.png" alt="about eneconomics global" className="w-100" /> <br />
          <img src="assets/images/someimages/vision.png"  className="w-100"alt="" /> <br />
        </div>
        <br />
        <div className="col-md-6">
          <h6>About the Eneconomics Global Solutions</h6>
          <p className="text-justify">
            We are a social impact nonprofit company that specializes in youth
            development (leadership, career, and business), civic engagements, and
            development-driven research. We are an indigenous youths-focused
            solution think-tank company in building a sustainable economically
            empowered Nation
          </p>{" "}
          <br />
        </div>
        
        <div className="col-md-12">
          <h6>Our Purpose</h6>
          <p>
            As a social impact company providing development-focused research, youth
            empowerment, and civic engagement services, we are dedicated to
            advocating and building a sustainable and economically empowered Nation
            through the Eneconomics Community.
          </p>{" "}
          <br />
          <p>
            Eneconomics is an independent and apolitical indigenous solution
            think-tank company born out of passion and a sense of responsibility
            devoted to meet contemporary sustainable economic development challenges
            of developing countries through advocating for indigenous solutions and
            engaging the youth community in the development process.
          </p>{" "}
          <br />
          <p>
            At Eneconomics, we believe that in proffering solutions for sustainable
            development challenges, the dynamics and complexity of each society or
            country should be prioritized.
          </p>{" "}
          <br />
          <p>
            <strong>The challenge and the alternative?</strong>
          </p>
          <p>
            <strong>
              We are a result-oriented, solution-driven company that brings together
              thinkers from a range of disciplines who challenge conventional wisdom
              and advance ideas to better serve society and drive Sustainable
              Development of African and other Developing Countries.
            </strong>
          </p>
          <br /> <br />
          <p>
            For Sustainable Development, Developing Countries needs leadership,
            infrastructure, and development, but the truth is that every country
            must develop of its own (indigenously), reflecting their specific needs
            and capabilities.
          </p>{" "}
          <br />
          <p>
            Development is not a product that we buy and give to a society. Implying
            that anytime development is outsourced ignoring indigenous capacity, the
            opportunity of the process of learning and capital accumulations is
            denied such a nation. This is because when countries build their
            development, they gain experience, build capital kept for
            sustainability.
          </p>{" "}
          <br />
          <p>Our approach is guided by a set of key principles:</p>
          <ul>
            <li>
              Both endogenous (Indigenous) and exogenous (Foreign) factors are
              required for sustainable economic development,
            </li>
            <li>
              Every economy has enough resources within and around to harness for
              sustainable economic development,
            </li>
            <li>
              To drive Sustainable Development of Developing Countries, there is
              need for engaging resources that are more indigenous in the
              development process.
            </li>
            <li>
              The youth community being the most active population should be
              actively engaged in developing and leading indigenous solutions.
            </li>
          </ul>
          <br />
        </div>
        <div className="col-md-6">
          <h6>ENECONOMICS APPROACH</h6>
          <p className="text-justify">
            Left to their own devices, individuals, governmental, and corporate
            institutions will continue to implement foreign policies and orthodox
            global development ideas, without considering their internal dynamics
            and indigenous factors.
          </p>
          <p className="text-justify">
            That is why Eneconomics through the Eneconomics Community carefully
            develops, incubates, and lead indigenous ideas that will drive
            sustainable development. We work to guide young professionals away from
            general global development orthodoxy and promote the indigenous ideas we
            develop and support among young professional influencers, policymakers,
            and the engaged public so that it can have real-world impact.
          </p>{" "}
          <br />
        </div>
        <div className="col-md-6">
          <h6>WE WORK WITH YOUNG PROFESSIONALS TO:</h6>
          <ul>
            <li>
              Commission and fund research that is solution-driven and
              development-focused. Amplify the research works and findings of
              members of the Eneconomics community, ensuring that their findings and
              ideas can have real-world impact.
            </li>
            <li>
              Eneconomics Consultative Forum: Designed to nurture a community
              (National and Global) of solution-driven and development-focused
              youths.
            </li>
            <li>
              Business Clinic: To mentor, coach MSMEs, and provide support
              Entrepreneurship development.
            </li>
            <li>
              Host events: Support a global network of solution-driven,
              result-oriented, and development-focused youths by bringing together
              young professionals, influencers, and policymakers to brainstorm,
              innovate, and lead indigenous solutions for a sustainable future of
              developing countries.
            </li>
          </ul>{" "}
          <br />
        </div>
        <div className="col-md-12">

          <h6>WE WORK WITH INFLUENCERS, AND POLICY MAKERS TO:</h6>
          <p>
            Apply researched indigenous thinking to policy questions for sustainable
            development of developing countries.
          </p>
          <p>
            Demystify Sustainable Development for the engaged public through our
            blog and video content, social media channels, and events.
          </p>
          <br />
          <p>Things we do include but not limited to:</p>
          <br />
          <p>
            <span className="text-decoration-underline">
              Commission and fund research
            </span>{" "}
            that are solution-driven and development-focused for policymaking
            purposes
          </p>{" "}
          <br />
          <p>
            <span className="text-decoration-underline">
              Eneconomics Consultative Forum{" "}
            </span>{" "}
            designed to nurture a National and Global Community of Young
            Professionals, Influencers, and Policy Makers to develop, incubate, and
            share indigenous ideas for sustainable development of developing
            countries.
          </p>{" "}
          <br />
          <p>
            <span className="text-decoration-underline">Business Clinic</span> to
            mentor and coach MSMEs, as well as provide support for entrepreneurship
            development
          </p>{" "}
          <br />
          <p>
            <span className="text-decoration-underline">Host Events</span> that
            brings together the youth community (young professionals, students,
            influencers, and policymakers) from all discipline to brainstorm,
            develop and lead indigenous ideas for Sustainable Development. These
            events are in form of symposiums, workshops, seminars, conferences, and
            summits
          </p>{" "}
          <br />
        </div>
      </div>
    </PageWrapper>
  );
}

export default AboutPage;
