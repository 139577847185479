import React from "react";

function FaqSection() {
  return (
    <section id="faq" className="faq">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Frequently Asked Questions</h2>
        </div>
        <ul className="faq-list accordion" data-aos="fade-up">
          <li>
            <a
              data-bs-toggle="collapse"
              className="collapsed"
              data-bs-target="#faq1"
            >
              - What is Eneconomics?{" "}
              <i className="bx bx-chevron-down icon-show" />
              <i className="bx bx-x icon-close" />
            </a>
            <div id="faq1" className="collapse" data-bs-parent=".faq-list">
              <p>
                Eneconomics is the Trademark name of Eneconomics Global
                Solutions LTD/GTE a nonprofit, youth-based, and apolitical
                company focused on developing and promoting bright indigenous
                solutions for Sustainable Development through the STRATEGIC
                engagement of the TALENTS of the Youth Community.
                <br />
                Eneconomics Global Solutions LTD/GTE is registered with the
                Corporate Affairs Commission of Nigeria with Registration (RC)
                Number 1944092 to engage in Youth Empowerment Programs, Civic
                Engagement, and Consultancy Services (Research and Business).
              </p>
            </div>
          </li>
          <li>
            <a
              data-bs-toggle="collapse"
              data-bs-target="#faq2"
              className="collapsed"
            >
              - What are our Key Area of Focus?{" "}
              <i className="bx bx-chevron-down icon-show" />
              <i className="bx bx-x icon-close" />
            </a>
            <div id="faq2" className="collapse" data-bs-parent=".faq-list">
              <p>
                Sustainable Development of developing countries is the focus of
                all activities of Eneconomics with focus on three (3) key areas
                contemporary challenges that includes:{" "}
              </p>
              <ul>
                <li>Research</li>
                <li>Youth Participation in Leadership and governance, and</li>
                <li>Business Development</li>
              </ul>
              <p>
                Moral and intellectual integrity is at the core of everything
                Eneconomics does.
              </p>
            </div>
          </li>
          <li>
            <a
              data-bs-toggle="collapse"
              data-bs-target="#faq3"
              className="collapsed"
            >
              - Who does Eneconomics Work With?{" "}
              <i className="bx bx-chevron-down icon-show" />
              <i className="bx bx-x icon-close" />
            </a>
            <div id="faq3" className="collapse" data-bs-parent=".faq-list">
              <p>
                Eneconomics is opened to the corporate network of any charity –
                to work with Governments, businesses, and NGOs to deliver real
                impact.
                <br />
                Eneconomics engage the foremost Young Professionals, Influencers
                (government, businesses, nongovernmental organizations, and
                other leaders of society), student researchers, and Policymakers
                to create indigenous solutions for sustainable development of
                countries.
              </p>
            </div>
          </li>
          <li>
            <a
              data-bs-toggle="collapse"
              data-bs-target="#faq4"
              className="collapsed"
            >
              - What is Eneconomics’ Mission, Vision, and Core Values?{" "}
              <i className="bx bx-chevron-down icon-show" />
              <i className="bx bx-x icon-close" />
            </a>
            <div id="faq4" className="collapse" data-bs-parent=".faq-list">
              <p>
                <strong>Vision:</strong>To build an economically empowered
                country driven by indigenous ideas where the youth community is
                inspired and equipped for inclusion and active participation in
                national sustainable development process.
                <br />
                <strong> Mission: </strong>To engage the Youth Community in
                brainstorming, innovating, and leading indigenous ideas for a
                sustainable economically empowered Country.
                <br />
                <strong>Core Value:</strong> The core values of Eneconomics are
                in 4Ds – Diligence, Discipline, Determination, and Development
              </p>
            </div>
          </li>
          <li>
            <a
              data-bs-toggle="collapse"
              data-bs-target="#faq5"
              className="collapsed"
            >
              Who is who? <i className="bx bx-chevron-down icon-show" />
              <i className="bx bx-x icon-close" />
            </a>
            <div id="faq5" className="collapse" data-bs-parent=".faq-list">
              <p>
                <strong>Principal Consultant: </strong> A world-renowned leader
                (in any capacity) who participates in any Eneconomics events as
                a speaker.
                <br />
                <strong>Delegate: </strong> A young professional, influencer,
                scholar, or policymaker participating in their first Eneconomics
                National or International events.
                <br />
                <strong> Eneconomist: </strong> An Eneconomist is a member of
                the Eneconomics Global Community. Eneconomists comprises of
                young professionals, influencers, scholars, and/or policymaker
                who has attended an Eneconomics event (National or
                International) as a Delegate.
                <br />
                <strong>Eneconomics Nations: </strong>Any country that the
                Eneconomics Vision is operational is an Eneconomics Nation
              </p>
            </div>
          </li>
          <li>
            <a
              data-bs-toggle="collapse"
              data-bs-target="#faq6"
              className="collapsed"
            >
              What is Eneconomics Consultative Forum ECF{" "}
              <i className="bx bx-chevron-down icon-show" />
              <i className="bx bx-x icon-close" />
            </a>
            <div id="faq6" className="collapse" data-bs-parent=".faq-list">
              <p>
                ECF is a platform established to engage the foremost Young
                Professionals, Influencers (government, businesses,
                nongovernmental organizations, and other leaders of society),
                student researchers, and Policymakers to{" "}
                <strong>Brainstorm</strong>, <strong>Develop</strong>,
                <strong>Incubate</strong>, and <strong>Lead</strong> indigenous
                ideas for Sustainable Development of Developing Countries.
                <br />
                The Forum was flagged-off on 2nd June, 2022 in Abuja with Prince
                Adewole Adebayo the Social Democratic Party’s Presidential
                Candidate in Nigeria’s 2023 General Elections as
                Chairman/Keynote Speaker.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default FaqSection;
