import React from "react";

function Jobs({ profile, job }) {
  return (
    <div
      className="tab-pane fade show"
      id="Job"
      role="tabpanel"
      aria-labelledby="Job-tab"
    >
      <ul className="list-unstyled mb-4 mt-3">
      <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Job Status:</span>{" "}
          <span>{profile?.job?.jobStatus}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Job Title:</span>{" "}
          <span>{profile?.job?.jobTitle}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Company:</span>{" "}
          <span>{profile?.job?.company}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Job Country:</span>{" "}
          <span>{profile?.job?.jobCountry}</span>
        </li>
       
      </ul>
    </div>
  );
}

export default Jobs;
