import React from "react";
import UserInfoBox from "./UserInfoBox";

function DashboardMain() {
  const profile = JSON.parse(localStorage.getItem("profile"))?.result;

  return (
    <div>
      <UserInfoBox profile={profile} />
    </div>
  );
}

export default DashboardMain;
