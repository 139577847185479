import React from "react";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>
                Eneconomics<span>.</span>
              </h3>
              <p>
                Suite 60/63 Block C, <br />
                EFAB Ext. EFAB Mall, Area 11 Garki,
                <br />
                Abuja-Nigeria <br />
                <br />
                <strong>Phone:</strong> +234 808 510 2016
                <br />
                <strong>Email:</strong> info@eneconomics.com
                <br />
              </p>
            </div>
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right" /> <a href="#">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#about">About us</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#services">Services</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="/login">Events</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="/login">Shop</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#faq">FAQ</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="/privacy">Privacy Policy</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" /> <a href="#">Blog</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#">Civic Engagement</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#">Youth Empowerment</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#">Consultancy Services </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#">Host Events </a>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <a href="#">Eneconomics Consultative Forum </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 footer-newsletter">
              <h4>Join Our Newsletter</h4>
              {/* <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna
              </p> */}
              <form>
                <input type="email" name="email" />
                <input type="submit" defaultValue="Subscribe" />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-md-flex py-4">
        <div className="me-md-auto text-center text-md-start">
          <div className="copyright">
            © Copyright{" "}
            <strong>
              <span>Eneconomics</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="mailto:emcintegratedsolutions@gmail.com">
              EMC INTEGRATED SOLUTIONS
            </a>
          </div>
        </div>
        <div className="social-links text-center text-md-end pt-3 pt-md-0">
          <a href="https://twitter.com/Eneconomics_GS?t=_HIvM5gM4FhC519UxfILwA&s=08" className="twitter">
            <i className="bx bxl-twitter" />
          </a>
          <a href="https://www.facebook.com/eneconomics/" className="facebook">
            <i className="bx bxl-facebook" />
          </a>
          <a
            href="https://www.instagram.com/eneconomics/"
            className="instagram"
          >
            <i className="bx bxl-instagram" />
          </a>
          <a href="https://www.linkedin.com/company/eneconomics/" className="linkedin">
            <i className="bx bxl-linkedin" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
