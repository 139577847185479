import LoginRequired from "./LoginRequired";
const renderFunction = (user, children) => {
  switch (user) {
    case null:
      return <LoginRequired />;

    default:
      return children;
  }
};

const PrivateRoute = ({ user, children }) => {
  return renderFunction(user, children);
};

export default PrivateRoute;
