import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo/ene logo-1.png";
import { useDispatch, useSelector } from "react-redux";
import { forgotpassword } from "../store/actions/authActions";
import { toast, ToastContainer } from "react-toastify";
import { clearNotifications } from "../store/actions/notificationsActions";

function ForgetPassword() {
  const [email, setEmail] = React.useState("");
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  React.useEffect(() => {
    if (notification?.errors?.message) {
      const { message } = notification?.errors;
      toast.error(message);
    }
    if (notification?.success?.message) {
      const { message } = notification?.success;
      toast.success(message);
    }
    dispatch(clearNotifications());
  }, [dispatch, notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(forgotpassword({email}));
  };
  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center text-dark">
          <Col sm={7} md={7} lg={6} xl={4} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="text-center">
                    <img src={Logo} className="w-50" alt="eneconomics logo" />
                  </div>
                  <div className="text-left m-2">
                    <p className="fs-6">
                      please enter your email address associated with your
                      account and we send you a link to reset your email
                    </p>
                  </div>
                  <div className="mb-3">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter email"
                          name="email"
                          value={email}
                          required
                        />
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Don't have an account?{" "}
                        <Link to="/register" className="text-primary fw-bold">
                          Sign Up
                        </Link>
                      </p>
                      <p className="mb-0  text-center">
                        <Link to="/login" className="text-primary fw-bold">
                          Back to Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer position="top-right" />
    </div>
  );
}

export default ForgetPassword;
