import React from "react";
import { Container, Row } from "react-bootstrap";
import CustomTab from "../../Components/tab/Tabs";
import Jobs from "./Jobs";
import Education from "./Education";
import "./profile.css";
import Bio from "./Bio";
import { Link } from "react-router-dom";

function ProfilePage() {
	const tabs = ["Bio", "Education", "Job"];
	const [profile, setProfile] = React.useState({});
	React.useEffect(() => {
		setProfile(JSON.parse(localStorage.getItem("profile"))?.result);
	}, []);

	return (
		<Container className='emp-container'>
			<Row>
				<div className='col-md-4'>
					<div className='profile-img'>
						<img src='assets/images/default-avatar.jpg' alt='' />
						<div className='file btn btn-lg btn-primary'>
							Change Photo
							<input type='file' name='file' />
						</div>
					</div>

					<div class='col-md-4 mt-4'>
						<div class='profile-work'>
							<small className='text-muted text-uppercase'>Interests</small>
						</div>
					</div>
				</div>
				<div className='col-md-6'>
					<div className='profile-head'>
						<h4 className='text-dark'>{profile?.name}</h4>
						<h6>{profile?.job?.jobTitle}</h6>

						<CustomTab tabs={tabs} isActive={tabs[0]}>
							<Bio profile={profile} />
							<Education profile={profile} />
							<Jobs profile={profile} />
						</CustomTab>
					</div>
				</div>
				<div className='col-md-2'>
					<Link to='/dashboard/update-profile' className='btn btn-primary'>
						update
					</Link>
				</div>
			</Row>
		</Container>
	);
}

export default ProfilePage;
