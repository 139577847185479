import React from "react";
import { Card, Form, Button, Row } from "react-bootstrap";

function WorkDetails({ inputValue, handleChange, handlePrev, handleNext }) {
	return (
		<Card className='shadow text-left m-2'>
			<Card.Body>
				{" "}
				<div className='mb-3'>
					<Form onSubmit={handleNext}>
						<Row>
							<Form.Group
								controlId='validationCustom01'
								className='mb-3 col-md-6 '>
								<Form.Label>Job Status</Form.Label>
								<Form.Control
									as='select'
									name='jobStatus'
									defaultValue={inputValue.jobStatus}
									onChange={handleChange}>
									<option>Select Status</option>
									<option value='Full_Time'>Full Time</option>
									<option value='Part_Time'>Part Time</option>
									<option value='Not_Employed'>Not Employed</option>
									<option value='Employed'>Employed</option>
									<option value='Self_Employed'>Self Employed</option>
								</Form.Control>
							</Form.Group>

							<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
								<Form.Label>Job Title</Form.Label>
								<Form.Control
									type='text'
									onChange={handleChange}
									placeholder='Job Title'
									name='jobTitle'
									value={inputValue.jobTitle}
									required
								/>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
								<Form.Label>Company</Form.Label>
								<Form.Control
									type='text'
									onChange={handleChange}
									placeholder='Company'
									name='company'
									value={inputValue.company}
									required
								/>
							</Form.Group>

							<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
								<Form.Label>Country</Form.Label>
								<Form.Control
									type='text'
									onChange={handleChange}
									placeholder='Country'
									name='jobCountry'
									value={inputValue.jobCountry}
									required
								/>
							</Form.Group>
						</Row>
						<Row>
							<div className=' col-6 text-left'>
								<Button variant='danger' onClick={handlePrev}>
									Previous
								</Button>
							</div>
							<div className=' col-6 text-right'>
								<Button variant='primary' type='submit'>
									Next
								</Button>
							</div>
						</Row>
					</Form>
				</div>
			</Card.Body>
		</Card>
	);
}

export default WorkDetails;
