import React from "react";
import { Card, Row, Button } from "react-bootstrap";

function Preview({ inputValue, handlePrev, handleSubmit }) {
  return (
    <Card className="shadow m-2 text-left">
      <Card.Body>
        <small className="text-muted text-uppercase">Personal Details</small>
        <ul className="list-unstyled mb-4 mt-3">
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Date of Birth:</span>{" "}
            <span>{inputValue?.birthDate}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Gender:</span>{" "}
            <span>{inputValue?.gender}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Residential Address:</span>{" "}
            <span>{inputValue?.address}</span>
          </li>

          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-check" />
            <span className="fw-semibold mx-2">Nationality:</span>{" "}
            <span>{inputValue?.nationality}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-check" />
            <span className="fw-semibold mx-2">Country of Residence:</span>{" "}
            <span>{inputValue?.residentCountry}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-check" />
            <span className="fw-semibold mx-2">City:</span>{" "}
            <span>{inputValue?.city}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-check" />
            <span className="fw-semibold mx-2">Postal Code:</span>{" "}
            <span>{inputValue?.postalCode}</span>
          </li>
        </ul>
        <small className="text-muted text-uppercase">Contacts</small>
        <ul className="list-unstyled mb-4 mt-3">
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-phone" />
            <span className="fw-semibold mx-2">Mobile Number:</span>{" "}
            <span>{inputValue?.phone}</span>
          </li>

          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-envelope" />
            <span className="fw-semibold mx-2">Email:</span>{" "}
            <span>{inputValue?.email}</span>
          </li>
        </ul>
        <small className="text-muted text-uppercase">Education Details</small>
        <ul className="list-unstyled mb-4 mt-3">
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Academic Status:</span>{" "}
            <span>{inputValue.academicStatus}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Institution:</span>{" "}
            <span>{inputValue.institution}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Specialization:</span>{" "}
            <span>{inputValue.specialisation}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Year of Graduation:</span>{" "}
            <span>{inputValue.graduationYear}</span>
          </li>

          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-check" />
            <span className="fw-semibold mx-2">Highst Degree Level:</span>{" "}
            <span>{inputValue.higestDegreeLevel}</span>
          </li>
        </ul>
        <small className="text-muted text-uppercase">Employment Details</small>
        <ul className="list-unstyled mb-4 mt-3">
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Job Title:</span>{" "}
            <span>{inputValue.jobTitle}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Company:</span>{" "}
            <span>{inputValue.company}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <i className="bx bx-user" />
            <span className="fw-semibold mx-2">Job Country:</span>{" "}
            <span>{inputValue.jobCountry}</span>
          </li>
        </ul>
        <Row>
          <div className=" col-6 text-left">
            <Button variant="danger" onClick={handlePrev}>
              Previous
            </Button>
          </div>
          <div className=" col-6 text-right">
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default Preview;
