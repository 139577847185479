import React from "react";
import { Container } from "react-bootstrap";
import CompleteBooking from "./form/CompleteBooking";
import { useDispatch, useSelector } from "react-redux";
import { eventregister } from "../../store/actions/userProfileActions";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import createGuest from "cross-domain-storage/guest";

function EventBooking() {
  const [eventInfo, setEventInfo] = React.useState();
  const eventStorage = createGuest("https://events.eneconomics.org/");

  React.useEffect(() => {
    eventStorage.get("event-info", function (error, value) {
      if (error) return error;

      if (value) setEventInfo(JSON.parse(value));
    });
  }, [eventStorage]);

  // const [payment, setPayment] = React.useState(false);
  const [inputValue, setInputValue] = React.useState({
    idType: "",
    idNumber: "",
    hasAttended: "no",
    previousEventNum: "",
    previousEvent: "",
    reasonForAttending: "",
    experienceExpectation: "",

    paymentInfo: {},
    eventInfo,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification = useSelector((state) => state.notification);

  React.useEffect(() => {
    if (notification?.success?.message) {
      const { message } = notification?.success;
      toast.success(message);
      eventStorage.remove("event-info", (err, data) => {
        return;
      });

      setTimeout(() => {
        navigate("/dashboard/my-events");
      }, 3000);
    }
    if (notification?.errors?.message) {
      const { message } = notification?.errors;
      toast.error(message);
    }
    dispatch(clearNotifications());
  }, [dispatch, notification, navigate, eventStorage]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputValue((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let eventData = { ...inputValue, ...eventInfo };
    eventData.previousEventNum = Number(inputValue.previousEventNum);

    dispatch(eventregister(eventData));
  };

  return (
    <>
      <Container>
        <CompleteBooking
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </Container>

      <ToastContainer position="top-right" />
    </>
  );
}

export default EventBooking;
