import React from "react";
import { Card, Form, Button, Row } from "react-bootstrap";

function PersonalDetails({ handleChange, inputValue, handleNext }) {
  return (
    <Card className="shadow text-left m-2">
      <Card.Body>
        {" "}
        <div className="mb-3">
          <Form onSubmit={handleNext}>
            <Row>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom01"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="Enter firstname"
                  name="firstname"
                  value={inputValue.firstname}
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom02"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="Enter lastname"
                  name="lastname"
                  value={inputValue.lastname}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  onChange={handleChange}
                  placeholder="Enter email"
                  name="email"
                  value={inputValue.email}
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom04"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  name="phone"
                  value={inputValue.phone}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom04"
              >
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  onChange={handleChange}
                  placeholder="date of birth"
                  name="birthDate"
                  value={inputValue.birthDate}
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom02"
              >
                <Form.Label>Home Address</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="home address"
                  name="address"
                  value={inputValue.address}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-md-6" controlId="formTitle">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  defaultValue={inputValue.gender}
                  onChange={handleChange}
                >
                  <option> select your Gender</option>
                  <option value="Male">Male </option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom05"
              >
                <Form.Label>Nationality</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="Nationality"
                  name="nationality"
                  value={inputValue.nationality}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom07"
              >
                <Form.Label>Country of Residence</Form.Label>
                <Form.Control
                  type="country"
                  onChange={handleChange}
                  placeholder="Country of Residence"
                  name="residentCountry"
                  value={inputValue.residentCountry}
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom08"
              >
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="City"
                  name="city"
                  value={inputValue.city}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom10"
              >
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="Postal Code"
                  name="postalCode"
                  value={inputValue.postalCode}
                  required
                />
              </Form.Group>
            </Row>

            <div className="d-grid">
              <Button variant="primary" type="submit">
                Next
              </Button>
            </div>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
}

export default PersonalDetails;
