import React from "react";
import PageWrapper from "./PageWrapper";

function Leadership() {
  return (
    <PageWrapper pageTitle="Leadership">
      <h6>Leadership</h6>
      <p>
        At Eneconomics, integrity is at the core of our activities and we
        believe firmly that the direction of progress we take on is very much
        dependent on leadership.{" "}
      </p>{" "}
      <br />
      <h6>Advisory Board</h6>
      <p>
        The advisory board is a group of carefully selected experts who lend
        their skills, guidance, and knowledge to help Eneconomics grow and
        achieve set goals.
      </p>
      <p>Prince Adewole Adebayo, Esq.</p>
      <p>Dr. (Mrs.) Jumai Ahmadu</p>
      <p>Elder Alabi J. E.</p>
      <br />
      <h6>Office of the Executive Secretary</h6>
      <ul>
        <li>Executive Secretary </li>
        <li>Admin </li>
        <li>Public Affairs Bureau/Media </li>
        <li>Summit </li>
        <li>ICT </li>
      </ul>{" "}
      <br />
      <h6>Research College</h6>
      <ul>
        <li>Chancellor </li>
        <li>Head of Faculties </li>
        <li>Head of Departments </li>
        <li>Research Fellows </li>
      </ul>{" "}
      <br />
      <h6>Eneconomics Youth Consultative Forum</h6>
      <ul>
        <li>Country Director </li>
        <li>Lead Country Researchers and Speakers </li>
        <li>State Coordinators (researchers/speakers) </li>
      </ul>{" "}
      <br />
      <h6>Business Clinic Africa</h6>
    </PageWrapper>
  );
}

export default Leadership;
