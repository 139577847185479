import React from "react";

function ClientSection({ data }) {
  const Swiper = window.Swiper;
  React.useEffect(() => {
    new Swiper(".clients-slider", {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 80,
        },
        992: {
          slidesPerView: 6,
          spaceBetween: 120,
        },
      },
    });
  }, [Swiper]);
  return (
    <section
      id="partners"
      className="clients"
      style={{ backgroundColor: "white" }}
    >
      <div className="container" data-aos="zoom-in">
      <div className="section-title">
          <h2 className="text-dark">Our Partners</h2>
        
        </div>
        <div className="clients-slider swiper">
          <div className="swiper-wrapper align-items-center">
            {data?.map((partner, i) => {
              return (
                <div className="swiper-slide" key={partner.name + i}>
                  <img
                    src={partner.logo}
                    className="img-fluid"
                    alt={partner.name}
                    // style={{ width: "400px", height: "140px" }}
                  />
                </div>
              );
            })}
          </div>
          <div className="swiper-pagination" />
        </div>
      </div>
    </section>
  );
}

export default ClientSection;
