import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../assets/images/Logo/ene logo-1.png'
import Terms from "../Components/Terms";

export default function Partnership() {
  const [inputValue, setInputValue] = useState({ name:"", email: "", phonenumbeer:"", company:"",subject:"", description:"" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInputValue((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(inputValue);
    setLoading(false);
  };

  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center text-dark">
          <Col sm={7} md={7} lg={6} xl={4} xs={12}>
            <div className=""></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="text-center">
                    <img src={Logo} className="w-50" alt="eneconomics logo" />
                    <h3 className="mt-3 mb-4 text-dark">Become a Partner</h3>
                  </div>
                  <div className="mb-3">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter your name"
                          name="name"
                          value={inputValue.name}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          onChange={handleChange}
                          placeholder="Enter email"
                          name="email"
                          value={inputValue.email}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="validationCustom04">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="tel"
                          onChange={handleChange}
                          placeholder="Enter phone number"
                          name="phonenumber"
                          value={inputValue.phonenumber}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Company/Organization</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter Company/Organization Name"
                          name="company"
                          value={inputValue.company}
                          required
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="validationCustom01">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter Subject"
                          name="subject"
                          value={inputValue.subject}
                          required
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="12" controlId="validationCustom05">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          placeholder="Description"
                          name="description"
                          value={inputValue.description}
                          required
                          onChange={handleChange}
                        />
                        <Form.Control.Feedback type="invalid">
                          enter description
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          required
                          label="Agree to terms and conditions"
                          feedback="You must agree before submitting."
                          feedbackType="invalid"
                          data-bs-toggle="modal"
                          data-bs-target="#terms-modal"
                          onChange={(e) =>
                            e.target.checked
                              ? setInputValue({ ...inputValue, terms: true })
                              : setInputValue({ ...inputValue, terms: false })
                          }
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          required
                          label="I'm not a robot"
                          feedback="confirm that you are not a robot."
                          feedbackType="invalid"
                          onChange={(e) =>
                            e.target.checked
                              ? setInputValue({ ...inputValue, terms: true })
                              : setInputValue({ ...inputValue, terms: false })
                          }
                        />
                      </Form.Group>

                      <div className="d-grid">
                        <Button
                          disabled={loading}
                          variant="primary"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        {" "}
                        <Link to="/partnership" className="text-primary fw-bold">
                          Back
                        </Link>
                      </p>
                    </div>
                  </div>
                  <Terms/>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
