import * as api from "../api/index.js";
export const updateprofile = (formData, router) => async (dispatch) => {
	try {
		const { data } = await api.profileUpdate(formData);

		dispatch({ type: "GET_SUCCESS_MSG", payload: data });
		dispatch({ type: "AUTH", data });
	} catch (error) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: error?.response?.data,
		});
	}
};

export const updateimage = (formData, router) => async (dispatch) => {
	try {
		const { data } = await api.imageUpdate(formData);

		dispatch({ type: "AUTH", data });
	} catch (error) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: error?.response?.data,
		});
	}
};

export const eventregister = (formData) => async (dispatch) => {
	try {
		const { data } = await api.eventRegister(formData);
		dispatch({ type: "GET_SUCCESS_MSG", payload: data });
	} catch (error) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: error?.response?.data,
		});
	}
};

export const eventpayment = (formData) => async (dispatch) => {
	try {
		const { data } = await api.eventPayment(formData);
		dispatch({ type: "GET_SUCCESS_MSG", payload: data });
	} catch (error) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: error?.response?.data,
		});
	}
};

export const getevents = (formData) => async (dispatch) => {
	try {
		const { data } = await api.getEvents(formData);
		dispatch({ type: "GET_MY_EVENTS", payload: data });
	} catch (error) {
		console.log(error);
	}
};
