import React from "react";
import SideMenu from "./SideMenu";

function DashboardContainer(props) {
	return (
		<section className='user-panel-section pt-150 pb-150'>
			<div className='fluid-container '>
				<div className='row m-2'>
					<SideMenu />
					<div className='col-lg-9'>
						<div className='user-panel-wrapper'>{props.children}</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default DashboardContainer;
