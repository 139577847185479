import React from "react";

function Bio({ profile }) {
  return (
    <div
      className="tab-pane fade show active"
      id="Bio"
      role="tabpanel"
      aria-labelledby="Bio-tab"
    >
      <ul className="list-unstyled mb-4 mt-3">
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">user name:</span>{" "}
          <span>{profile?.name}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Date of Birth:</span>{" "}
          <span>{profile?.birthDate}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Gender:</span>{" "}
          <span>{profile?.gender}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Residential Address:</span>{" "}
          <span>{profile?.address}</span>
        </li>

        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-check" />
          <span className="fw-semibold mx-2">Nationality:</span>{" "}
          <span>{profile?.nationality}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-check" />
          <span className="fw-semibold mx-2">Country of Residence:</span>{" "}
          <span>{profile?.residentCountry}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-check" />
          <span className="fw-semibold mx-2">City:</span>{" "}
          <span>{profile?.city}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-check" />
          <span className="fw-semibold mx-2">Postal Code:</span>{" "}
          <span>{profile?.postalCode}</span>
        </li>
      </ul>
      <small className="text-muted text-uppercase">Contacts</small>
      <ul className="list-unstyled mb-4 mt-3">
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-phone" />
          <span className="fw-semibold mx-2">Mobile Number:</span>{" "}
          <span>{profile?.phone}</span>
        </li>

        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-envelope" />
          <span className="fw-semibold mx-2">Email:</span>{" "}
          <span>{profile?.email}</span>
        </li>
      </ul>
    </div>
  );
}

export default Bio;
