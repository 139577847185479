import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

function Header() {
  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  /**
   * Easy event listener function
   */
  const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all);
    if (selectEl) {
      if (all) {
        selectEl.forEach((e) => e.addEventListener(type, listener));
      } else {
        selectEl.addEventListener(type, listener);
      }
    }
  };
  React.useEffect(() => {
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });
  });
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      {/* <div className="container d-flex align-items-center">
        <Link to="/" className="logo me-auto">
          <img src="assets/images/Logo/ene-logo.png" alt="" />
        </Link>
        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <a className="nav-link scrollto active" href="#hero">
                Home
              </a>
            </li>

            <li>
              <a className="nav-link scrollto" href="#services">
                Services
              </a>
            </li>
            <li>
              <a className="nav-link scrollto " href="#portfolio">
                Research College
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="https://events.eneconomics.org"
              >
                Events
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/partnership">
                Partnership
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/login">
                Forum
              </a>
            </li>
            <li>
              <a href="blog.html">Blog</a>
            </li>
            <li className="dropdown">
              <a href="#about">
                <span>About Us</span> <i className="bi bi-chevron-down" />
              </a>
              <ul>
                <li>
                  <a href="#about">Our Purpose</a>
                </li>
                <li>
                  <a href="/leadership">Leadership</a>
                </li>
                <li>
                  <a href="#">Eneconomics in the News</a>
                </li>
                <li>
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
            </li>
            <li>
              <a className="nav-link scrollto" href="#">
                Thoughts
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/login">
                Shop
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle" />
        </nav>
        <Link to="/register" className="get-started-btn">
          Get Started
        </Link>
        <Link to="/login" className="get-started-btn">
          Login
        </Link>
      </div> */}

      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        className="w-100 bg-transparent"
        variant="transparent"
      >
        <Container>
          <Navbar.Brand id="logo" className="me-auto">
            <Link to="/" className="logo me-auto">
              <img src="assets/images/Logo/ene-logo.png" alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="Tog" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto offset-2">
              <Nav.Link href="#hero" className="text-warning">
                Home
              </Nav.Link>
              <Nav.Link href="#services" className="text-warning">
                Services
              </Nav.Link>
              <Nav.Link href="/" className="text-warning">
                Research College
              </Nav.Link>
              <Nav.Link
                href="/https://events.eneconomics.org"
                className="text-warning"
              >
                Events
              </Nav.Link>
              <Nav.Link href="/partnership" className="text-warning">
                Partnership
              </Nav.Link>
              <NavDropdown title="About" className="text-warning" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#about">About</NavDropdown.Item>
                <NavDropdown.Item href="#about">Purpose</NavDropdown.Item>
                <NavDropdown.Item href="/leadership">
                  Leadership
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Eneconomics in the news
                </NavDropdown.Item>
                <NavDropdown.Item href="#contact">Contact Us</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/login" className="text-warning">
                Forum
              </Nav.Link>
              <Nav.Link href="/" className="text-warning">
                Blog
              </Nav.Link>
              <Nav.Link href="/" className="text-warning">
                Thoughts
              </Nav.Link>
              <Nav.Link href="/login" className="text-warning">
                Shop
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link eventKey={2} className="get-started-btn" href="/register">
                Get Started
              </Nav.Link>
              <Nav.Link eventKey={2} className="get-started-btn" href="/login">
                Login
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
