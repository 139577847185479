import React from "react";

function Store() {
  return (
    <div className="user-info-area">
      <div className="nk-block-between-md g-4">
        <div className="nk-block-head-content">
          <h2 className="nk-block-title fw-normal">Check Later</h2>
          <div className="nk-block-des">
            <p>Feature coming soon!!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Store;
