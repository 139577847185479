import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./landingpages/Index";
import "./App.css";
import axios from "axios";
import Login from "./auth/Login";
import Register from "./auth/Register";
import Dashboard from "./dashboard/Index";
import DashboardMain from "./dashboard/DashboardMain";
import ProfilePage from "./dashboard/profile/Index";
import Forums from "./dashboard/forums/Forums";
import Store from "./dashboard/store/Store";
import Events from "./dashboard/events/Events";
import AboutPage from "./landingpages/pages/AboutPage";
import Leadership from "./landingpages/pages/Leadership";
import Partners from "./landingpages/pages/Partners";
import Partnership from "./landingpages/pages/Partnership";
import BecomePartner from "./Forms/Partnership";
import Privacy from "./landingpages/pages/Privacy";
import CompleteBio from "./dashboard/profile/CompleteBio";
import ForgetPassword from "./auth/ForgetPassword";
import ResetMain from "./auth/ResetMain";
import EmailConfirmation from "./auth/EmailConfirmation";
import PrivateRoute from "./common/PrivateRoute";
import EventBooking from "./dashboard/events/EventBooking";
import MyEvents from "./dashboard/events/MyEvents";
import createHost from "cross-domain-storage/host";
import PageNotFound from "./landingpages/pages/404Page";

function App() {
	React.useEffect(() => {
		if (process.env.NODE_ENV === "production") {
			axios({
				url: "https://eneconomics-api.onrender.com/",
				method: "GET",
			})
				.then((res) => {
					console.log(res.data);
				})

				.catch((err) => {});
		}
	}, []);

	createHost([
		{
			origin: "https://events.eneconomics.org",
			allowedMethods: ["get", "set", "remove"],
		},
		{
			origin: "http://localhost:3001",
			allowedMethods: ["get", "set", "remove"],
		},
	]);

	const user = JSON.parse(localStorage.getItem("profile"))?.result;
	return (
		<div className='App'>
			<Routes>
				<Route path='*' element={<PageNotFound />} />
				<Route path='/' element={<LandingPage />} />
				<Route path='/login' element={<Login />} />
				<Route path='/register' element={<Register />} />
				<Route path='/verify_email' element={<EmailConfirmation />} />
				<Route path='/forgotpassword' element={<ForgetPassword />} />
				<Route path='/passwordreset' element={<ResetMain />} />{" "}
				<Route path='/about' element={<AboutPage />} />
				<Route path='/becomepartner' element={<BecomePartner />} />
				<Route path='/leadership' element={<Leadership />} />
				<Route path='/partners' element={<Partners />} />
				<Route path='/partnership' element={<Partnership />} />
				<Route path='/privacy' element={<Privacy />} />
				<Route
					path='/dashboard'
					element={
						<PrivateRoute user={user}>
							{" "}
							<Dashboard />
						</PrivateRoute>
					}>
					<Route index element={<DashboardMain />} />
					<Route path='profile' element={<ProfilePage />} />
					<Route path='forums' element={<Forums />} />
					<Route path='events' element={<Events />} />
					<Route path='book-event' element={<EventBooking />} />
					<Route path='my-events' element={<MyEvents />} />
					<Route path='store' element={<Store />} />
					<Route
						path='complete-bio'
						element={<CompleteBio title='Complete Bio' />}
					/>
					<Route
						path='update-profile'
						element={<CompleteBio title='Update Profile' />}
					/>
				</Route>
			</Routes>
		</div>
	);
}

export default App;
