const clientReducer = (state = { myEvents: [], isLoading: true }, action) => {
  switch (action.type) {
    case "GET_MY_EVENTS":
      return { ...state, myEvents: action.payload, isLoading: false };

    default:
      return state;
  }
};

export default clientReducer;
