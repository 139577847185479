import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/Logo/ene logo-1.png";
import { useDispatch, useSelector } from "react-redux";
import { signin } from "../store/actions/authActions";
import { toast, ToastContainer } from "react-toastify";
import { clearNotifications } from "../store/actions/notificationsActions";

export default function Login() {
  const [inputValue, setInputValue] = useState({ email: "", password: "" });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  React.useEffect(() => {
    if (notification?.errors?.message) {
      const { message } = notification?.errors;
      toast.error(message);
      dispatch(clearNotifications());
    }
  }, [dispatch, notification]);
  const handleChange = (event) => {
    const { name, value } = event.target;

    setInputValue((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(signin(inputValue, navigate));
  };

  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center text-dark">
          <Col sm={7} md={7} lg={6} xl={4} xs={12}>
            <div className=""></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="text-center">
                    <img src={Logo} className="w-50" alt="eneconomics logo" />
                    <h3 className="mt-3 mb-4 text-dark">Login</h3>
                  </div>
                  <div className="mb-3">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          onChange={handleChange}
                          placeholder="Enter email"
                          name="email"
                          value={inputValue.email}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          onChange={handleChange}
                          placeholder="Password"
                          name="password"
                          value={inputValue.password}
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3 d-flex justify-content-between align-items-center"
                        controlId="formBasicCheckbox"
                      >
                        <p className="small">
                          <Link to="/forgotpassword" className="text-primary">
                            Forgot password?
                          </Link>
                        </p>
                        <p className="small">
                        <Link to="/" className="text-primary">
                          Back to Home
                        </Link>
                      </p>
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Login
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Don't have an account?{" "}
                        <Link to="/register" className="text-primary fw-bold">
                          Sign Up
                        </Link>
                      </p>
                     
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer position="top-right" />
    </div>
  );
}
