import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import decode from "jwt-decode";

function SideMenu() {
	const [user, setUser] = React.useState(
		JSON.parse(localStorage.getItem("profile"))
	);
	const [clicked, setClicked] = React.useState(true);
	React.useEffect(() => {
		const $ = window.$;

		$(".user-panel-sidebar-btn").on("click", function () {
			$(".user-panel-sidebar").slideToggle(clicked);
		});
	}, [clicked]);

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useNavigate();

	const logout = React.useCallback(() => {
		dispatch({ type: "LOGOUT" });

		history("/login");

		setUser(null);
	}, [dispatch, history]);

	React.useEffect(() => {
		const token = user?.token;

		if (token) {
			const decodedToken = decode(token);
			if (decodedToken.exp * 1000 < new Date().getTime()) logout();
		}
		if (!token) history("/login");
		setUser(JSON.parse(localStorage.getItem("profile")));
	}, [history, location, logout, user?.token]);

	return (
		<div className='col-lg-3'>
			<span
				className='user-panel-sidebar-btn'
				onClick={() => setClicked(!clicked)}>
				Sidebar menu
				<i className='fa fa-bars' />
			</span>
			<ul className='user-panel-sidebar'>
				<li className='active'>
					<Link to='/dashboard'>Home</Link>
				</li>
				<li>
					<Link to='profile'>Profile</Link>
				</li>
				<li>
					<Link to='events'>Event Application</Link>
				</li>
				<li>
					<Link to='my-events'>My Event</Link>
				</li>
				<li>
					<Link to='store'>Store</Link>
				</li>
				<li>
					<Link to='forums'>Forums</Link>
				</li>

				<li>
					<button
						className='btn bg-transparent text-danger p-0'
						onClick={() => {
							logout();
						}}>
						Sign Out
					</button>
				</li>
			</ul>
		</div>
	);
}

export default SideMenu;
