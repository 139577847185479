import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo/ene logo-1.png";
import { useDispatch, useSelector } from "react-redux";
import { resetpassword, clearAuthNotif } from "../store/actions/authActions";
import { toast, ToastContainer } from "react-toastify";

function PaswordReset({ id, email }) {
  const [inputValue, setInputValue] = React.useState({
    password: "",
    confirmPassword: "",
  });

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (auth.success.message) {
      const { message } = auth.success;
      toast.success(message);
      dispatch(clearAuthNotif());
    }
    if (auth.errors.message) {
      const { message } = auth.errors;
      toast.error(message);
      dispatch(clearAuthNotif());
    }
  }, [dispatch, auth]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    inputValue.id = id;

    inputValue.email = email;
    dispatch(resetpassword(inputValue));
  };
  return (
    <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center text-dark">
          <Col sm={7} md={7} lg={6} xl={4} xs={12}>
            <div className=""></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="text-center">
                    <img src={Logo} className="w-50" alt="eneconomics logo" />

                    <h4 className="mt-3 mb-4 text-dark"> Password Reset 🔒</h4>
                    <p className="mb-4">
                      You are only one step a way from your new password,
                      recover your password now.
                    </p>
                  </div>
                  <div className="mb-3">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="password"
                          onChange={(e) =>
                            setInputValue({
                              ...inputValue,
                              password: e.target.value,
                            })
                          }
                          placeholder="enter new Password"
                          value={inputValue.password}
                          required
                          autoFocus
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          onChange={(e) =>
                            setInputValue({
                              ...inputValue,
                              confirmPassword: e.target.value,
                            })
                          }
                          placeholder="Repeat Password"
                          value={inputValue.confirmPassword}
                          required
                          autoFocus
                        />
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Reset password
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        <Link to="/login" className="text-primary fw-bold">
                          login Instead
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer position="top-right" />
    </div>
  );
}

export default PaswordReset;
