import React from "react";
import PageWrapper from "./PageWrapper";

function Privacy() {
  return (
    <PageWrapper pageTitle="Privacy Policy">
      <div className="container-fluid">
        <h2 className="text-center">Privacy Policy</h2>
        <p className="text-justify">
          Eneconomics Global Solutions LTD/GTE (“ENECONOMICS”) is committed to
          protecting your privacy online. Your use of{" "}
          <a href="https://www.eneconomics.org">www.eneconomics.org</a>,
          including the Business Clinic, the Consultative Forum Directory,
          ENECONOMICS Research College, and all other platforms, features and
          sites (the “Website”) indicates your acceptance of this Privacy Policy
          (the “Policy”). If you do not accept all of the terms of the Policy,
          you should not use the Website. This Policy may change, so please
          refer back to it periodically.
        </p>
        <h4 className="text-center p-3">
          How We Collect Information About You?
        </h4>
        <p className="text-justify p-3">
          We obtain information about you when you use our Website. For example,
          you may provide us information when you use our Website to become a
          member, create an account, subscribe to one of our blogs, comment on a
          post, make a donation, sign up for the Young Scholars Directory,
          participate in various user-related activities and discussion forums
          on ENECONOMICS Consultative Forum, register for an event, or use one
          of the other services, features or resources on the website. We may
          also obtain information about you when you apply for a grant from us
          or manage a grant from us.
        </p>
        <h4 className="text-center p-3">
          What Kinds of Information Do We Collect About You?
        </h4>
        <p className="text-justify p-3">
          “Personal Information” is information that would allow someone to
          identify or contact you, including, for example, your name,
          organization, address, phone number and email address. The Personal
          Information we ask for might include your name, address, country or
          area of residence, academic affiliation, level of education, student
          status, field of study, birthdate, gender, phone numbers, fax number,
          email address, social media online identifiers, tax or banking
          information, and IP address. If you make a donation, register for an
          event, or apply for a grant, you may be directed to a third-party
          website to process that transaction. They may collect additional
          information necessary to complete that transaction or application.
        </p>
        <h4 className="text-center p-3">How We Use Your Information.</h4>
        <p className="text-justify p-3">
          ENECONOMICS uses Personal Information collected about you to provide
          you with information and services, enable you to participate in
          features and platforms on the Website, customize our services to
          individual needs, provide user support, contact you regarding updates
          about the Website, receive and track donations, register and manage
          participation in events and conferences, award and administer grants,
          and to advance ENECONOMICS’s mission.
          <br />
          In the event you make a donation, seek expense reimbursement, or
          engage in other financial transactions, you grant us permission to
          access your credit card information or other financial information to
          complete your donation or financial transaction. ENECONOMICS will not
          share, rent, or sell this financial-related information to any third
          parties without your consent, except that your credit card and other
          Personal Information will be shared with our credit card processor or
          other financial intermediary and then only to process the transaction.
          <br />
          ENECONOMICS may share your Personal Information with its agents,
          affiliated entities, and partners for the purposes of completing tasks
          and providing services to you on our behalf. For example, if we
          partner with an institution to host an event and you register for that
          event, we may share your registration information with that
          institution.
          <br />
          ENECONOMICS will not sell, rent, or disclose Personal Information to
          unaffiliated mass marketers without your express permission. From time
          to time, ENECONOMICS may send you information from other like-minded
          organizations whose products or services we think you might find
          interesting but we do not exchange, trade, rent or give email lists to
          unaffiliated third parties.
        </p>
        <p className="text-justify p-2">
          If law enforcement or judicial authorities require us to provide them
          with your Personal Information, we must cooperate with the authorities
          if their request is supported with the necessary legal documentation.
          We reserve the right to disclose your Personal Information to protect
          the rights, property, safety or security of ENECONOMICS, our visitors,
          our registered users or the public.
        </p>
        <h4 className="text-center p-3">Legal Basis for Processing.</h4>
        <p className="text-justify p-3">
          Our legal basis for processing your Personal Information for the
          purposes described above will typically be consent, as you have
          consented to us using your Personal Information for the purposes
          discussed here. We may also process your Personal Information to enter
          into or perform a contract with you, or to comply with a legal
          obligation to which ENECONOMICS is subject. It is also in our
          legitimate interest to use your Personal Information to operate and
          improve our services, measure our impact, and to protect our
          organization.
        </p>
        <h4 className="text-center p-3">Nonpersonal Information.</h4>
        <p className="text-justify p-3">
          We may collect nonpersonal information about you when you visit the
          Website. Nonpersonal information may include browser types, access
          times, and technical information concerning connection to the Website.
          We may use this information for internal purposes, for example, to
          improve the Website, analyze usage patterns, and negotiate with
          vendors of Internet services. That information does not include your
          Personal Information.
        </p>
        <h4 className="text-center p-3">Security.</h4>
        <p className="text-justify p-3">
          ENECONOMICS treats security seriously and takes reasonable measures to
          protect each Website user’s Personal Information. ENECONOMICS is not,
          however, responsible for any information you share with other users of
          the Website or other third parties. While we strive to use
          industry-standard practices to protect your information, ENECONOMICS
          cannot guarantee the absolute security of your information. If you
          believe there has been any breach of the security of the Website,
          please contact us.
        </p>
        <h4 className="text-center p-3">
          Your Control of Personal Information.
        </h4>
        <p className="text-justify p-3">
          You may limit the collection and use of different elements of your
          Personal Information by not participating in those activities for
          which we collect Personal Information, as listed above. You may
          unsubscribe from communications you receive from us, or select the
          type of information you would like to receive, by following directions
          included in such communications.
          <br />
          You may request a copy of the Personal Information we hold about you
          by contacting us at the address below. You may ask us to correct any
          personal information or delete all information we hold about you by
          contacting us at the address below.
        </p>
        <h4 className="text-center p-3">
          Submitting Information to Public Areas of Website.
        </h4>
        <p className="text-justify p-3">
          Please be aware that if you decide to disclose Personal Information on
          an area of the Website accessible to other users, this information may
          become public. ENECONOMICS does not control the acts of users of the
          Website, so please be cautious when deciding to reveal Personal
          Information. Please contact us if you believe a user of the Website is
          improperly collecting or using information about you or other users.
        </p>
        <h4 className="text-center p-3">Cookies.</h4>
        <p className="text-justify p-3">
          A cookie is a small piece of information that a website can store on a
          computer and later use to recognize a user. The purpose of cookies is
          to enhance your ability to receive the information you want.
          ENECONOMICS may use cookies to enhance the experience of users of the
          Website. A session cookie expires when a user closes the browser in
          which the Website was viewed. You can personalize your browser
          settings to reject cookie sessions.
          <br />
          <br />
          We use Google Analytics to help analyze how visitors use the Website.
          Google Analytics uses cookies to collect standard Internet log
          information and visitor behavior in an anonymous form. This
          information is transmitted to Google and is used to generate reports
          to help us improve the Website. The information transmitted does not
          contain Personal Information. You can opt out of Google Analytics
          without affecting how you use the Website. For more information on
          opting out of tracking by Google Analytics across all websites you
          use, visit this Google page.
        </p>
        <h4 className="text-center p-3"> Links to Other Websites</h4>
        <p className="text-justify p-3">
          The Website may contain links to third-party websites. ENECONOMICS is
          not responsible for the privacy practices, policies, or content of
          those websites. Once you link to any third-party website, you are
          subject to the terms of service and privacy policy of that site. We
          encourage you to investigate and ask questions before disclosing
          information to third parties.
        </p>
        <h4 className="text-center p-3">Privacy Protection for Children</h4>
        <p className="text-justify p-3">
          If you are under the age of 16, please do not use the Website.
          ENECONOMICS will not knowingly permit persons under the age of 16 to
          provide information to ENECONOMICS through the Website or otherwise.
          If ENECONOMICS later learns that a user is under the age of 16,
          ENECONOMICS will take appropriate steps to remove that person.
        </p>
        <h4 className="text-center p-3">How Long We Hold Data For.</h4>
        <p className="text-justify p-3">
          <strong>
            We keep your Personal Information for as long as is necessary for
            the purposes for which it is processed. These periods vary depending
            on the nature of the information and your interactions with us.
          </strong>
        </p>
        <h4 className="text-center p-3">Notification of Changes.</h4>
        <p className="text-justify p-3">
          We strongly believe in respecting your privacy. We will update and
          amend this Policy if and when it is necessary. Please check back often
          to read our most updated and amended version, which is posted in the
          privacy policy section of the Website. Your continued use of the
          Website means that you consent to our updates and amendments.
        </p>
        <h4 className="text-center p-3">Contact Information.</h4>
        <p className="text-justify p-3">
          You may contact ENECONOMICS through any of the following ways:
          <br />
          Via Email: <a href="mailto:info@eneconomics.org">info@eneconomics.org</a>
          <br />
          Office Address: Eneconomics Global Solutions LTD/GTE, Suite 60/63 Block C, EFAB Ext. EFAB Mall, Area 11 Garki, Abuja-Nigeria, 900247
        <br />
        Tel: <a href="tel:+2348085102016">(+234)808-510-2016</a>
        </p>
      </div>
    </PageWrapper>
  );
}

export default Privacy;
