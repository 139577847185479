import React from "react";

function TeamSection() {
  return (
    <section id="team" className="team section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Team</h2>
          <p>
            We Work with Young Professionals, Influencers, Student Researchers,
            Policymakers
          </p>
        </div>
        <div className="row d-flex flex-wrap justify-content-center align-items-center">
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay={100}>
              <div className="member-img">
                <img
                  src="assets/images/Team/CEO.jpg"
                  className="img-fluid"
                  alt="ceo"
                />
                <div className="social">
                  <a href="twitter.com">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="facebook.com">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="instagram.com">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="linkedin.com">
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Enejo James</h4>
                <span>Founder &amp; Managing Consultant</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay={200}>
              <div className="member-img">
                <img
                  src="assets/images/Team/executive-secretary.jpg"
                  className="img-fluid"
                  alt="sec"
                />
                <div className="social">
                  <a href="twitter.com">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="facebook.com">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="instagram.com">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="linkedin.com">
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Alabi Esther Ladi</h4>
                <span>Executive Secretary</span>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay={300}>
              <div className="member-img">
                <img
                  src="assets/images/Team/public-affairs.jpg"
                  className="img-fluid"
                  alt="pub-affair"
                />
                <div className="social">
                  <a href="twitter.com">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="facebook.com">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="instagram.com">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="linkedin.com">
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Inyang Magdalene </h4>
                <span>Head, Public Affairs Bureau</span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay={400}>
              <div className="member-img">
                <img
                  src="assets/images/Team/editor-in-chief.jpg"
                  className="img-fluid"
                  alt="editor"
                />
                <div className="social">
                  <a href="twitter.com">
                    <i className="bi bi-twitter" />
                  </a>
                  <a href="facebook.com">
                    <i className="bi bi-facebook" />
                  </a>
                  <a href="instagram.com">
                    <i className="bi bi-instagram" />
                  </a>
                  <a href="linkedin.com">
                    <i className="bi bi-linkedin" />
                  </a>
                </div>
              </div>
              <div className="member-info">
                <h4>Alabi Gideon</h4>
                <span>Editor-In-Chief</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
