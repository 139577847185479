import React from "react";
import PageWrapper from "./PageWrapper";

function Partners() {
  return (
    <PageWrapper pageTitle="Our Partners">
      <div className="row g-1">
        <div className="col-sm-12 col-md-4">
          <img  className="w-100 h-50"src="assets/images/Partners/partner1.jpg" alt=" eneconomics Partners" />
        </div>
        <div className="col-sm-12 col-md-4">
          <img  className="w-100 h-50"src="assets/images/Partners/jahman logo.png" alt=" eneconomics Partners" />
        </div>
        <div className="col-sm-12 col-md-4">
          <img  className="w-100 h-50"src="assets/images/Partners/Elite Star Foundation logo-01.jpg" alt=" eneconomics Partners" />
        </div>
        
        <div className="col-sm-12 col-md-4">
          <img  className="w-100 h-50"src="assets/images/Partners/download (1).png" alt=" eneconomics Partners" />
        </div>
        <div className="col-sm-12 col-md-4">
          <img  className="w-100 h-50"src="assets/images/Partners/SAMFITZ WEARS.png" alt=" eneconomics Partners" />
        </div>
        <div className="col-sm-12 col-md-4">
          <img  className="w-100 h-50"src="assets/images/Partners/teela mock 2.jpg" alt=" eneconomics Partners" />
        </div>
      </div>
    </PageWrapper>
  );
}

export default Partners;
