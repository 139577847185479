import React from "react";
import PageWrapper from "./PageWrapper";
import { FaBullhorn, FaDotCircle, FaRegLightbulb, FaSignal } from 'react-icons/fa'

function Partnership() {
  return (
    <PageWrapper pageTitle="Partnership">
      <div className="container">
        <h2 className="text-center">Partnership</h2>
        <p className="text-justify">
          There is a growing expectation that businesses should make a positive
          impact beyond profits, and employees want to work for businesses that
          have purpose and are committed to sustainability. Eneconomics believes
          that business must be at the heart of social, environmental and
          economic change.
        </p>
        <br />
        <p className="text-justify">
          Eneconomics is opened to the corporate network of any charity – to
          work with Governments, businesses, and NGOs to deliver real impact.
          The Youth Community is critical in driving businesses and social
          developmental agenda to become better corporate and social citizens –
          through leveraging the power of the government, companies, and
          nongovernmental organizations positive change can be accelerated more
          so than ever before.
        </p> <br />
        <div className="row">
          <div className="col-md-6 p-3 d-flex justify-content-center flex-column align-items-center">
            <FaRegLightbulb className="text-center text-primary fs-1"/>
            <h4 className="text-center">Innovations</h4>
            <p className="text-center">
              Eneconomics engage the youth community (National and Global)
              through our various platforms to develop indigenous ideas for
              sustainable development
            </p>
          </div>
          <div className="col-md-6 p-3 d-flex justify-content-center flex-column align-items-center">
          <FaBullhorn className="text-center text-primary fs-1"/>
            <h4 className="text-center">Leading</h4>
            <p className="text-center">
              Eneconomics organizes a platform to champion key country-specific
              causes by powerful advocacy of pursuing sustainable development.
              Beyond sharing solutions, we make sure we lead the solutions
              developed by the Eneconomics Global Community.
            </p>
          </div>
          <div className="col-md-6 p-3 d-flex justify-content-center flex-column align-items-center">
          <FaDotCircle className="text-center text-primary fs-1"/>
            <h4 className="text-center">Networking</h4>
            <p className="text-center">
              Eneconomics is a global sustainable development solutions brand
              working with both public and private sector to identify and
              develop top indigenous talent for profitable results
            </p>
          </div>
          <div className="col-md-6 p-3 d-flex justify-content-center flex-column align-items-center">
          <FaSignal className="text-center text-primary fs-1"/>
            <h4 className="text-center">Sustainabilty</h4>
            <p className="text-center">
              Eneconomics is a global sustainable development solutions brand
              working with both public and private sector to identify and
              develop top indigenous talent for profitable results
            </p>
          </div>
        </div>
          <div className="d-flex flex-wrap justify-content-center align-items-center w-100 ">
            <a href="/becomepartner" className="get-started-btn scrollto">
              Become a Partner
            </a>
          </div>
      </div>
    </PageWrapper>
  );
}

export default Partnership;
