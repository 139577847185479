import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { css } from "@emotion/react";
import { DotLoader } from "react-spinners";
import {
	eventpayment,
	getevents,
} from "../../store/actions/userProfileActions";
import { Card, Container } from "react-bootstrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import EventDetailModal from "./EventDetailModal";
import { usePaystackPayment } from "react-paystack";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { toast, ToastContainer } from "react-toastify";

const override = css`
	display: block;
	margin: auto auto;
	border-color: #696cff;
`;
dayjs.extend(relativeTime);

function MyEvents() {
	const { isLoading, myEvents } = useSelector((state) => state.client);
	const notification = useSelector((state) => state.notification);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(getevents());
	}, [dispatch]);

	const [show, setShow] = React.useState(false);
	const [event, setEvents] = React.useState({});
	const [display, setDisplay] = React.useState("reg-info");

	const [inputValue, setInputValue] = React.useState({
		shirtSize: "",
		cpName: "",
		cpRelationship: "",
		cpPhone: "",
		cpAddress: "",
		hasMedCondition: "no",
		medicalCondition: "",

		paymentInfo: {},
	});

	const handleClose = () => {
		setDisplay("reg-info");
		setShow(false);
	};
	const handleShow = (selectedEvent) => {
		setShow(true);
		setEvents(selectedEvent);
	};

	React.useEffect(() => {
		if (notification?.success?.message) {
			const { message } = notification?.success;
			toast.success(message);
			//remove submitted payment from local storage
			localStorage.removeItem("payment-info");
			setDisplay("reg-info");
		}
		if (notification?.errors?.message) {
			const { message } = notification?.errors;
			toast.error(message);
		}
		dispatch(clearNotifications());
	}, [dispatch, notification]);

	const config = {
		reference: new Date().getTime().toString(),
		email: event?.clientEmail,
		amount: event?.price * 100,
		publicKey: "pk_test_23903d597343b2acb5b55a3f3d1685836bf94992",
	};

	const initializePayment = usePaystackPayment({
		...config,
		onClose: () => console.log("close"),
	});

	const onSuccess = (ref) => {
		let paymentInfo = {
			bankName: "paystack transaction",
			tellerNumber: ref.reference,
			tellerDate: dayjs().format("YYYY-MM-DD H:mm:ss"),
		};

		setInputValue((values) => ({ ...values, paymentInfo }));
		//save to localstorage on event that registration fails to avoid repeat payment
		localStorage.setItem("payment-info", JSON.stringify(paymentInfo));
		setDisplay("form");
		setShow(true);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setInputValue((values) => ({ ...values, [name]: value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		inputValue.id = event._id;
		dispatch(eventpayment(inputValue));
	};

	const handlePayment = () => {
		const paymentInfo =
			JSON.parse(localStorage.getItem("payment-info")) || null;

		if (paymentInfo) {
			setInputValue((values) => ({ ...values, paymentInfo }));
			setDisplay("form");
			return;
		}
		handleClose();
		initializePayment((res) => onSuccess(res));
	};

	if (isLoading)
		return (
			<div className='container'>
				<DotLoader
					color='#696cff'
					loading={isLoading}
					css={override}
					size={150}
				/>
			</div>
		);
	return (
		<Container className='emp-container'>
			<Card>
				<h2 className='card-header'>My Events</h2>
				<div
					className='table-responsive text-nowrap'
					style={{ minHeight: "50vh" }}>
					<table className='table'>
						<thead>
							<tr>
								<th>Event Name</th>
								<th>Participation Type</th>
								<th>Registration Date</th>
								<th>Status</th>
								<th>Actions</th>
							</tr>
						</thead>

						<tbody className='table-border-bottom-0'>
							{myEvents?.map((event, i) => (
								<tr key={event._id}>
									<td>
										<i className='fab fa-angular fa-lg text-warning me-3' />{" "}
										<strong>{event?.eventName}</strong>
									</td>
									<td>{event.participationType}</td>
									<td>
										<small className='text-muted'>
											registered {dayjs(event?.createdAt).fromNow()}
										</small>
									</td>
									<td>
										<span className='text-warning me-1'>{event.status}</span>
									</td>
									<td>
										<button
											className='dropdown-item'
											onClick={() => handleShow(event)}>
											<i className='bx bxs-user-detail me-1'></i> view details
										</button>
									</td>
								</tr>
							))}
							{myEvents?.length < 1 && (
								<div className='m-4'>
									{" "}
									<h2>You Are yet to register for an event</h2>
								</div>
							)}
						</tbody>
					</table>
				</div>
			</Card>
			<EventDetailModal
				handleClose={handleClose}
				handleShow={handleShow}
				handlePayment={handlePayment}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				inputValue={inputValue}
				setInputValue={setInputValue}
				display={display}
				event={event}
				show={show}
			/>
			<ToastContainer position='top-right' />
		</Container>
	);
}

export default MyEvents;
