import React from "react";
import { Link } from "react-router-dom";
import "./dashboard.css";

function UserInfoBox({ profile }) {
  return (
    <div className="user-info-area">
      <div className="nk-block-between-md g-4">
        <div className="nk-block-head-content">
          <h2 className="nk-block-title fw-normal">{profile?.name}</h2>
          {!profile?.job ? (
            <div className="nk-block-des text-white">
              <p className="text-white">
                Welcome, Please complete your bio to unlock features
              </p>
            </div>
          ) : (
            <div className="nk-block-des">
              <p>Welcome</p>
            </div>
          )}
        </div>
        <div className="nk-block-head-content d-none d-md-inline-flex">
          {!profile?.job && (
            <Link to="complete-bio" className="btn btn-primary  mx-3 mb-3">
              <span className="text-white">complete bio</span>{" "}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserInfoBox;
