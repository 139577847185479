import React from "react";
import { Card, Container } from "react-bootstrap";
import { verifyaccount } from "../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { css } from "@emotion/react";
import { DotLoader } from "react-spinners";
import "./emailconfirm.css";
import { clearNotifications } from "../store/actions/notificationsActions";

const override = css`
  display: block;
  margin: auto auto;
  border-color: #696cff;
`;
function EmailConfirmation() {
  const [verify, setVerify] = React.useState(null);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification);

  const queryString = window.location.search;
  const urlParam = new URLSearchParams(queryString);
  const token = urlParam.get("token");

  React.useEffect(() => {
    dispatch(verifyaccount(token));
  }, [dispatch, token]);

  React.useEffect(() => {
    if (notification?.success?.message) {
      setVerify("success");
    }
    if (notification?.errors?.message) {
      setVerify("failed");
    }
    dispatch(clearNotifications());
  }, [dispatch, notification]);

  return (
    <Container id="email-confirm">
      <div className="card-confirm ">
        {isLoading && (
          <DotLoader
            color="#696cff"
            loading={isLoading}
            css={override}
            size={150}
          />
        )}
        {verify === "success" && (
          <Card className="card-confirm text-center">
            <div className="card-head">
              <div className="icon-box">
                <i className="bx bxs-check-circle" />
              </div>
            </div>

            <Card.Body>
              <p className="text-success">
                Your account has been confirmed successfully
              </p>
            </Card.Body>
            {/* <Card.Footer>
            <button classname="btn btn-success btn-block">OK</button>
          </Card.Footer> */}
          </Card>
        )}
        {verify === "failed" && <h3> error: invalid code</h3>}
      </div>
    </Container>
  );
}

export default EmailConfirmation;
