import React from "react";
import { Link } from "react-router-dom";
import "./page-expired.css";

function ExpiredLinkPage() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            4<span>0</span>4
          </h1>
        </div>
        <h2 classname="mb-2 mx-2">Link Expired:(</h2>
        <p classname="mb-4 mx-2">
          Oops! 😖 The reset link expired or is incorrect, request a new link
        </p>
        <Link to="/" classname="btn btn-primary">
          Back to home
        </Link>
      </div>
    </div>
  );
}

export default ExpiredLinkPage;
