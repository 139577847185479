import React from "react";

function CountSection() {
  return (
    <section id="counts" className="counts">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="count-box">
              <i className="bi bi-emoji-smile" />
              <span
                data-purecounter-start={0}
                data-purecounter-end={100}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Diligence</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div className="count-box">
              <i className="bi bi-journal-richtext" />
              <span
                data-purecounter-start={0}
                data-purecounter-end={100}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Discipline</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="bi bi-headset" />
              <span
                data-purecounter-start={0}
                data-purecounter-end={100}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Dedication</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="count-box">
              <i className="bi bi-people" />
              <span
                data-purecounter-start={0}
                data-purecounter-end={100}
                data-purecounter-duration={1}
                className="purecounter"
              />
              <p>Development</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CountSection;
