import React from 'react'

function Terms() {
  return (
    <>
     {/* Modal Order Form */}
     <div id="terms-modal" className="modal fade">
     <div className="modal-dialog" role="document">
       <div className="modal-content">
         <div className="modal-header">
           <h4 className="modal-title text-dark">
             Terms of use
           </h4>
           <button
             type="button"
             className="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
           />
         </div>
         <div className="modal-body">
           <p className="text-secondary">
             Last Updated: 21 June, 2022
           </p>{" "}
           <br />
           <h3 className="text-dark ">Acceptance of Terms</h3>
           <p className="text-justify text-dark">
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             , including all site-related content and services
             (collectively, “
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             ”) is provided by the Eneconomics Global Solutions
             LTD/GTE for New Indigenous Ideas/Thinking/Solutions
             (“Eneconomics,” “we,” “us”). Your use of
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             is subject to your compliance with the terms and
             conditions set forth in this Terms of Use Agreement
             (“Terms of Use”), which supplement any additional
             guidelines or other terms posted by Eneconomics in
             relation to specific site-related services or
             content. Although you can access and read portions
             of{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             just by visiting the site, you must sign up to use
             certain
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             features – such as receiving newsletters, attending
             events, applying for grants. Use of{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             is governed by the terms of use policies posted on
             each of those sites. Please note that we reserve the
             right to update or modify the Terms of Use without
             advance notice by posting a revised Terms of Use to
             this website. Visitors to
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             (“you”) can determine whether the Terms of Use has
             been modified since your last visit by checking the
             “Last updated” legend posted at the top of the Terms
             of Use. By using{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             , you agree to be bound by the version of the Terms
             of Use then in effect. If at any point you no longer
             agree to the Terms of Use, please discontinue your
             use of{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             . Eneconomics may deny service to you at any time,
             including in the event you do not adhere to the
             Terms of Use.
           </p>{" "}
           <br />
           <h3 className="text-dark">
             About Eneconomics: The Purpose of{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
           </h3>
           <p className="text-dark text-justify">
             Eneconomics is a nonpartisan research, solutions,
             and education nonprofit company based in Nigeria.
             Our mission is to accelerate sustainable development
             of African and other Developing Countries by
             developing, incubating, and sharing (Country’s
             Specific) indigenous ideas that will lead to
             real-world solutions to the great challenges of the
             21st century and beyond.
           </p>
           <p className="text-justify text-dark">
             Being registered as a company Limited by Guarantee
             (LTD/GTE), revenues generated are committed into
             community-based projects – fund developmental
             research, support entrepreneurs etc. This implies
             that we are a tax-exempt company.
           </p>
           <p className="text-justify text-dark">
             As a tax-exempt company, Eneconomics is prohibited
             from supporting attempts to influence the outcome of
             any election for public office. Accordingly, with
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             , Eneconomics seeks to provide a forum to discuss
             research, new ideas, and policies – not to advance
             the interests of any particular political party or
             candidate for office.
           </p>{" "}
           <br />
           <h3 className="text-dark">
             Eneconomics’s Proprietary Rights and Re-Use Policy
           </h3>
           <p className="text-justify text-dark">
             You acknowledge and agree that the information and
             materials presented on or through{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             will remain the property of Eneconomics and its
             licensors or providers, and are protected by
             copyright, trademark, patent, and/or other
             proprietary rights and related laws, rules and
             regulations. The materials produced by or for
             Eneconomics (including editorial content,
             photographs, graphics, video and audio content) are
             protected by copyright as a collective work or
             compilation under the copyright laws of the Nigeria
             and other countries. All individual articles,
             content, and other elements comprising this
             compilation and produced by or for Eneconomics are
             also copyrighted works. You agree to abide by all
             additional copyright notices or restrictions
             contained in any materials produced by or for
             Eneconomics.
           </p>
           <p className="text-justify text-dark">
             The trademarks, service marks, logos and trade names
             displayed on{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             (collectively the “Marks”) are the registered and
             unregistered trademarks of Eneconomics,
             Eneconomics’s licensors and suppliers, and others.
             All Marks on
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             not owned by Eneconomics are the property of their
             respective owners. The Marks owned by Eneconomics,
             whether registered or unregistered, may not be used
             in connection with any product or service that is
             not Eneconomics’s. Please review our Re-Use Policy,
             which is incorporated into this Terms of Use by this
             reference, to determine how you may use certain
             materials posted on{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             .
           </p>{" "}
           <br />
           <h3 className="text-dark">Links</h3>
           <p className="text-dark text-justify">
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             provides links to other websites and resources.
             Because Eneconomics has no control over these sites
             and resources, you acknowledge and agree that
             Eneconomics does not endorse and is not responsible
             or liable for any content, advertising, products, or
             other materials on or available from such sites or
             resources. Eneconomics is providing these links to
             you only as a convenience. Eneconomics has the right
             but not the obligation to block links from or to
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             at any time.
           </p>{" "}
           <br />
           <h3 className="text-dark">
             Claims of Copyright Infringement
           </h3>
           <p className="text-justify text-dark">
             Eneconomics respects the intellectual property
             rights of others. It is the policy of Eneconomics to
             respond promptly to notices of alleged copyright
             infringement that comply with the law. Eneconomics
             reserves the right to delete or disable content
             alleged to be infringing and terminate the accounts
             of repeat infringers. Potential infringement should
             be reported per our Digital Millennium Copyright Act
             (“DMCA”) Policy below. Our Designated Agent
             (“Designated Agent”) under the DMCA is:
             <br />
             Sarah Philips <br />{" "}
             <a href="mailto:sphilips@eneconomics.org">
               sphilips@eneconomics.org
             </a>
             , <br /> Suite 60/63, Block C, <br /> EFAB Ext. EFAB
             Mall, <br /> Area 11 Garki, <br />
             Abuja-Nigeria, <br />{" "}
             <a href="mailto:copyright@eneconomics.org">
               copyright@eneconomics.org
             </a>
           </p>
           <br />
           <h3 className="text-dark">DMCA Policy</h3>
           <p className="text-justify text-dark">
             Copyright owners or those authorized to act on
             behalf of one or authorized to act under any
             exclusive right of copyright, may report alleged
             copyright infringement occurring on the INET
             platform by completing the following DMCA Notice of
             Alleged Infringement (the “Notice”) and delivering
             it to the Designated Agent. Any such Notice must:
           </p>
           <p className="text-justify text-dark">
             Identify the copyrighted work for which infringement
             is claimed. If the Notice is intended to cover
             multiple copyrighted works, you may provide a
             representative list of the copyrighted works for
             which infringement is claimed. Identify the material
             or link that you claim is infringing the copyrighted
             work and for which you wish access to be disabled.
             At minimum, this includes the URL of the link shown
             on INET or the exact location where the material may
             be found.
             <br />
             Provide your company affiliation (if applicable),
             mailing address, telephone number, and, if
             available, email address. Include both of the
             following statements in the body of the Notice:
             <br />
             “I hereby state that I have a good faith belief that
             the disputed use of the copyrighted material is not
             authorized by the copyright owners, its agent, or
             the law (e.g., as a fair use).”
             <br />
             “I hereby state the information in this Notice is
             accurate and, under penalty of perjury, that I am
             the owner, or authorized to act on behalf of, the
             owner, of the copyright or of an exclusive right
             under the copyright that is allegedly infringed.”
             Provide your full legal name and your electronic or
             physical signature.
           </p>
           <br />
           <h3 className="text-dark">Privacy Policy</h3>
           <p className="text-dark text-justify">
             <strong>
               All information provided by you or collected by us
               during visits to{" "}
               <a href="https://www.eneconomics.org">
                 {" "}
                 eneconomics.org{" "}
               </a>{" "}
               (including when you register to become a member or
               sign up to use certain features) is governed by
               Eneconomics’s Privacy Policy, which is hereby
               incorporated into the Terms of Use by this
               reference. Please review it carefully by clicking
               here.{" "}
             </strong>
           </p>{" "}
           <br />
           <h3 className="text-dark">Jurisdictional Issues</h3>
           <p className="text-justify text-dark">
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             is controlled and operated by Eneconomics from its
             principal office in Abuja – the Federal Capital
             Territory, Nigeria, and is not intended to subject
             Eneconomics to the laws or jurisdiction of any other
             state, country or territory. Those who choose to
             access{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             do so on their own initiative and at their own risk,
             and are solely responsible for complying with all
             local laws, rules, and regulations.
           </p>
           <br />
           <h3 className="text-dark">Termination</h3>
           <p className="text-dark text-justify">
             You agree that Eneconomics, in its sole discretion,
             may terminate your use of{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             or any part thereof, at any time and for any reason,
             including if Eneconomics believes that you have
             violated or acted inconsistently with the spirit of
             the Terms of Use. Further, you agree that
             Eneconomics will not be liable to you or any third
             party for any termination of your access to
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             .
           </p>
           <br />
           <h3 className="text-dark">Disclaimers</h3>
           <p className="text-dark text-justify">
             <a href="https://www.eneconomics.org">
               {" "}
               ENECONOMICS.ORG{" "}
             </a>{" "}
             IS PROVIDED “AS IS” WITHOUT WARRANTIES OF ANY KIND,
             WHETHER EXPRESS, IMPLIED OR STATUTORY. YOU AGREE
             THAT YOU MUST EVALUATE AND BEAR ALL RISKS ASSOCIATED
             WITH THE USE OF ENECONOMICS.ORG, AND ANY RELIANCE ON
             THE ACCURACY, COMPLETENESS, OR USEFULNESS THEREOF.
             TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE
             LAW, ENECONOMICS DISCLAIMS ALL WARRANTIES WITH
             RESPECT TO
             <a href="https://www.eneconomics.org">
               {" "}
               ENECONOMICS.ORG{" "}
             </a>{" "}
             .
           </p>
           <p className="text-justify text-dark">
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             may include inadvertent inaccuracies or errors, or
             information or materials that violate the Terms of
             Use. Additionally, the possibility exists that a
             third party could make unauthorized alterations to{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             content or features. Although we attempt to ensure
             the integrity of
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             , we make no guarantees as to its completeness or
             correctness. In the event that a situation arises in
             which{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>
             's completeness or correctness is in question,
             please contact us with, if possible, a description
             of the material to be checked and the location (URL)
             where such material can be found on{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>
             , as well as information sufficient to enable us to
             contact you.
           </p>
           <br />
           <h3 className="text-dark">Limitation of Liability</h3>
           <p className="text-dark text-justify">
             NEITHER ENECONOMICS NOR ANY OF OUR AFFILIATES,
             GUARANTORS, OFFICERS, EMPLOYEES, AMBASSADORS, OR
             CONSULTANTS ARE RESPONSIBLE OR LIABLE FOR ANY
             INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
             EXEMPLARY, PUNITIVE OR OTHER DAMAGES UNDER ANY
             CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER
             THEORY ARISING OUT OF OR RELATING IN ANY WAY TO
             <a href="https://www.eneconomics.org">
               {" "}
               ENECONOMICS.ORG{" "}
             </a>{" "}
             AND/OR MATERIALS CONTAINED THEREON, ANY LINKED SITE
             OR ANY CONTENT OR SERVICE AVAILABLE THROUGH{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               ENECONOMICS.ORG{" "}
             </a>
             . YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE
             SITE, CONTENT, SERVICES OR ANY LINKED SITE IS TO
             STOP YOUR USE OF IT.
           </p>
           <br />
           <h3 className="text-dark">Indemnification</h3>
           <p className="text-justify text-dark">
             You agree to defend, indemnify, and hold harmless
             Eneconomics and our Guarantors, officers, employees,
             Ambassadors and consultants, from and against any
             and all claims, damages, losses, costs (including
             reasonable attorneys’ fees) and other expenses that
             arise out of or from: (a) your activities in
             connection with{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>
             ; (b) any violation of these Terms of Use by you; or
             (c) any allegation that any submissions or other
             materials you submit to us or transmit to
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             infringe or otherwise violate the copyright,
             trademark, trade secret, or other intellectual
             property or other rights of any third party.
           </p>
           <br />
           <h3 className="text-dark">Miscellaneous</h3>
           <p className="text-dark text-justify">
             These Terms of Use are governed by and construed in
             accordance with the laws of the Federal Republic of
             Nigeria, without regard to its conflict of law
             principles. You agree to submit to the exclusive
             jurisdiction of any court located in the
             jurisdiction of the Federal Republic of Nigeria, and
             waive any jurisdictional, venue, or inconvenient
             forum objections to such courts, in any matter
             arising out of or related to{" "}
             <a href="https://www.eneconomics.org">
               {" "}
               eneconomics.org{" "}
             </a>{" "}
             or these Terms of Use. If any provision of these
             Terms of Use is found to be unlawful, void or for
             any reason unenforceable, then that provision will
             be deemed severable from these Terms of Use and will
             not affect the validity and enforceability of any
             remaining provisions. Eneconomics may assign its
             rights and obligations under these Terms of Use.
             These, together with all policies referred to
             herein, is the entire agreement between us relating
             to the subject matter herein and supersedes any and
             all prior or contemporaneous written or oral
             agreements between us with respect to such subject
             matter.
           </p>
         </div>
       </div>
       {/* /.modal-content */}
     </div>
     {/* /.modal-dialog */}
   </div>
   {/* /.modal */}
   </>
  )
}

export default Terms
