import React from "react";
import Header from "./Header";
import Hero from "./Hero";
import ClientSection from "./ClientSection";
import AboutSection from "./AboutSection";
import CountSection from "./CountSection";
import ServicesSection from "./ServicesSection";
import Testimonial from "./Testimonial";
import TeamSection from "./TeamSection";
import FaqSection from "./FaqSection";
import ContactSection from "./ContactSection";
import Footer from "./Footer";
import JsonData from "../data/data.json";

function Index() {
  const [landingPageData, setLandingPageData] = React.useState({});
  React.useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  console.log();
  return (
    <>
      <Header />
      <Hero data={landingPageData.Hero} />
      <AboutSection />
      <CountSection />
      <ServicesSection />
      <Testimonial />
      <ClientSection data={landingPageData.Partners} />
      <TeamSection />
      <FaqSection />
      <ContactSection />
      <Footer />
    </>
  );
}

export default Index;
