import React from "react";
import { Link } from "react-router-dom";

function DashboardHeader() {
  React.useEffect(() => {
    const $ = window.$;

    var fixed_top = $(".header-section");
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 50) {
        fixed_top.addClass("animated fadeInDown menu-fixed");
      } else {
        fixed_top.removeClass("animated fadeInDown menu-fixed");
      }
    });
  });

  return (
    <header className="header-section">
      <div className="header-bottom">
        <div className="container">
          <nav className="navbar navbar-expand-xl p-0 align-items-center">
            <Link className="site-logo site-title" to="/dashboard">
              <img
                src="assets/images/Logo/ene logo-1.png"
                alt="eneconomics-logo"
                style={{ width: "10em", maxHeight: "50px" }}
              />
              <span className="logo-icon">
                <i className="flaticon-fire" />
              </span>
            </Link>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            ></div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default DashboardHeader;
