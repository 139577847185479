import React from "react";

function Events() {
	return (
		<div className='user-info-area'>
			<div className='nk-block-between-md g-4'>
				<div className='nk-block-head-content'>
					<div className='nk-block-des text-white'>
						<p className='text-white'>
							Welcome, Please click the button to see all events
						</p>
					</div>
				</div>
				<div className='nk-block-head-content  d-md-inline-flex'>
					<a
						href='https://events.eneconomics.org'
						className='btn btn-primary  mx-3 mb-3'>
						<span className='text-white'>view events</span>{" "}
					</a>
				</div>
			</div>
		</div>
	);
}

export default Events;
