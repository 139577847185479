import React from "react";
import { Card, Form, Button, Row } from "react-bootstrap";

function CompleteBooking({
	handleChange,
	handleSubmit,
	inputValue,
	setInputValue,
}) {
	return (
		<Card className='shadow text-left m-2'>
			<div className='divider'>
				<div className='divider-text'>
					{" "}
					<h3 className='card-header'> Event Booking Form</h3>
				</div>
			</div>
			<Card.Body>
				{" "}
				<div className='mb-3'>
					<Form onSubmit={handleSubmit}>
						<Row>
							<Form.Group
								controlId='validationCustom01'
								className='mb-3 col-md-6 '>
								<Form.Label>ID Type</Form.Label>
								<Form.Select
									name='idType'
									defaultValue={inputValue.idType}
									onChange={handleChange}>
									<option>Select ID type</option>
									<option value='voters card'>Voter's Card</option>
									<option value='NIN'>NIN</option>
									<option value="driver's license">Driver's License</option>
								</Form.Select>
							</Form.Group>
							{inputValue?.idType && (
								<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
									<Form.Label>ID Number</Form.Label>
									<Form.Control
										type='text'
										onChange={handleChange}
										placeholder={`enter ${inputValue?.idType} number`}
										name='idNumber'
										value={inputValue.idNumber}
										required
									/>
								</Form.Group>
							)}
						</Row>

						<Row>
							<Form.Group controlId='formdradio' className='col-lg-12 my-3'>
								<Form.Label className='m-2'>
									Have you attended an Eneconmics Event before?{" "}
								</Form.Label>
								<Form.Check
									onChange={(e) =>
										setInputValue({
											...inputValue,
											hasAttended: e.target.value,
										})
									}
									type='radio'
									value='yes'
									label='Yes'
									name='hasAttended'
									inline
									required></Form.Check>
								<Form.Check
									onChange={(e) =>
										setInputValue({
											...inputValue,
											hasAttended: e.target.value,
										})
									}
									type='radio'
									value='no'
									label='No'
									name='hasAttended'
									inline
									required></Form.Check>
							</Form.Group>
						</Row>

						{inputValue.hasAttended === "yes" && (
							<Row>
								<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
									<Form.Label>How Many</Form.Label>
									<Form.Control
										type='number'
										onChange={handleChange}
										placeholder='Number of Eneconomics Events attended'
										name='previousEventNum'
										value={inputValue.previousEventNum}
										required
									/>
								</Form.Group>
								<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
									<Form.Label>Which Event have you attended?</Form.Label>
									<Form.Control
										type='text'
										onChange={handleChange}
										placeholder='mention event'
										name='previousEvent'
										value={inputValue.previousEvent}
									/>
								</Form.Group>
							</Row>
						)}

						<Row>
							<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
								<Form.Label>Why do you want to attend this event?</Form.Label>
								<Form.Control
									as='textarea'
									rows={3}
									onChange={handleChange}
									placeholder=''
									name='reasonForAttending'
									value={inputValue.reasonForAttending}
								/>
							</Form.Group>
							<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
								<Form.Label>
									What do you hope to gain from the experience?
								</Form.Label>
								<Form.Control
									as='textarea'
									rows={3}
									onChange={handleChange}
									placeholder=''
									name='experienceExpectation'
									value={inputValue.experienceExpectation}
									required
								/>
							</Form.Group>
						</Row>
						{/* <div className="divider">
              <div className="divider-text">
                {" "}
                <h3 className="card-header"> Emergency Contact</h3>
              </div>
            </div>
            <Row>
              <Form.Group className="mb-3 col-md-6" controlId="formTitle">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="enter full name of emergency contact"
                  name="cpName"
                  value={inputValue.cpName}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-6" controlId="formTitle">
                <Form.Label>Relationship</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="enter relationship with emergency contact"
                  name="cpRelationship"
                  value={inputValue.cpRelationship}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 col-md-6" controlId="formTitle">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  onChange={handleChange}
                  placeholder="enter phone number of emergency contact"
                  name="cpPhone"
                  value={inputValue.cpPhone}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3 col-md-6" controlId="formTitle">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="enter address of emergency contact"
                  name="cpAddress"
                  value={inputValue.cpAddress}
                  required
                />
              </Form.Group>
            </Row>
            <div className="divider">
              <div className="divider-text">
                {" "}
                <h3 className="card-header"> Medical Information</h3>
              </div>
            </div>
            <Row>
              <Form.Group controlId="formdradio" className="col-lg-6 my-3">
                <Form.Label className="m-2">
                  Do you have a medical condition?{" "}
                </Form.Label>
                <Form.Check
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      hasMedCondition: e.target.value,
                    })
                  }
                  type="radio"
                  value="yes"
                  label="Yes"
                  name="hasMedCondition"
                  inline
                  required
                ></Form.Check>
                <Form.Check
                  onChange={(e) =>
                    setInputValue({
                      ...inputValue,
                      hasMedCondition: e.target.value,
                    })
                  }
                  type="radio"
                  value="no"
                  label="No"
                  name="hasMedCondition"
                  inline
                  required
                ></Form.Check>
              </Form.Group>
              {inputValue.hasMedCondition === "yes" && (
                <Form.Group className="mb-3 col-md-6" controlId="formTitle">
                  <Form.Label>Medical condition</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    placeholder="please state your medical condition"
                    name="medicalCondition"
                    value={inputValue.medicalCondition}
                    required
                  />
                </Form.Group>
              )}
            </Row> */}
						<Row>
							<div className='d-grid'>
								<Button variant='primary' type='submit'>
									submit
								</Button>
							</div>
						</Row>
					</Form>
				</div>
			</Card.Body>
		</Card>
	);
}

export default CompleteBooking;
