import React from "react";
import { Form, Card, Row, Button } from "react-bootstrap";

function EducationDetails({
  inputValue,
  handleChange,
  handleNext,
  handlePrev,
}) {
  return (
    <Card className="shadow text-left m-2">
      <Card.Body>
        {" "}
        <div className="mb-3">
          <Form onSubmit={handleNext}>
            <Row>
              <Form.Group
                controlId="validationCustom01"
                className="mb-3 col-md-6 "
              >
                <Form.Label>Academic Status</Form.Label>
                <Form.Select
                  aria-label="Default select academic-status"
                  name="academicStatus"
                  defaultValue={inputValue.academicStatus}
                  onChange={handleChange}
                >
                  <option>Select Status</option>
                  <option value="Postgraduate">Postgraduate</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Undergraduate">Undergraduate</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom02"
              >
                <Form.Label>Institution (School/University)</Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  placeholder="Institution"
                  name="institution"
                  value={inputValue.institution}
                  required
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                controlId="validationCustom02"
                className="mb-3 col-md-6"
              >
                <Form.Label>Specialization/Major</Form.Label>
                <Form.Select
                  aria-label="Default select specialisation"
                  name="specialisation"
                  defaultValue={inputValue.specialisation}
                  onChange={handleChange}
                >
                  <option>Select Area of Specialization</option>
                  <option value="Agriculture and Related Sciences">
                    Agriculture and Related Sciences
                  </option>
                  <option value="Arts and Humanity Studies">
                    Arts and Humanity Studies
                  </option>
                  <option value="Aviation">Aviation</option>
                  <option value="Biological and Biomedical Science">
                    Biological and Biomedical Science
                  </option>
                  <option value="Business Administration, Business Management">
                    Business Administration, Business Management
                  </option>
                  <option value="Communication, Journalism">
                    Communication, Journalism
                  </option>
                  <option value="Computer and Information Sciences">
                    Computer and Information Sciences
                  </option>
                  <option value="Cyber Security and Cloud Architecture">
                    Cyber Security and Cloud Architecture
                  </option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Economics and Finance">
                    Economics and Finance
                  </option>
                  <option value="Education">Education</option>
                  <option value="Engineering Technologies and Engineering-Related Fields">
                    Engineering Technologies and Engineering-Related Fields
                  </option>
                  <option value="English Language and Literature and Linguistics">
                    English Language and Literature and Linguistics
                  </option>
                  <option value="Environmental Studies and related Natural Resources Studies">
                    Environmental Studies and related Natural Resources Studies
                  </option>
                  <option value="Ethnic, Cultural, and Gender Studies">
                    Ethnic, Cultural, and Gender Studies
                  </option>
                  <option value="Foreign Languages, Literatures, and Linguistics">
                    Foreign Languages, Literatures, and Linguistics
                  </option>
                  <option value="Game art and Game Development">
                    Game art and Game Development
                  </option>
                  <option value="Graphic and Digital Design">
                    Graphic and Digital Design
                  </option>
                  <option value="Human Rights">Human Rights</option>
                  <option value="Human Sciences">Human Sciences</option>
                  <option value="Industrial Systems">Industrial Systems</option>
                  <option value="Information Systems">
                    Information Systems
                  </option>
                  <option value="International Systems">
                    International Systems
                  </option>
                  <option value="Law and Legal Studies">
                    Law and Legal Studies
                  </option>
                  <option value="Management and Innovation">
                    Management and Innovation
                  </option>
                  <option value="Marketing, PR, and Advertising">
                    Marketing, PR, and Advertising
                  </option>
                  <option value="Media, Entertainment, and Film Making Production">
                    Media, Entertainment, and Film Making Production
                  </option>
                  <option value="Medical Studies">Medical Studies</option>
                  <option value="Others">Others</option>
                  <option value="Performing Arts">Performing Arts</option>
                  <option value="Philosophy">Philosophy</option>
                  <option value="Physical and Psychological Science">
                    Physical and Psychological Science
                  </option>
                  <option value="Political, Economic Researcher and Related Fields">
                    Political, Economic Researcher and Related Fields
                  </option>
                  <option value="QA Engineering & Validation">
                    QA Engineering & Validation
                  </option>
                  <option value="Science Mathematics and Related Studies">
                    Science Mathematics and Related Studies
                  </option>
                  <option value="Security and Law Enforcement">
                    Security and Law Enforcement
                  </option>
                  <option value="Social Sciences">Social Sciences</option>
                  <option value="Software Development">
                    Software Development
                  </option>
                  <option value="Support Services">Support Services</option>
                  <option value="Technologies/Technicians and Support Services">
                    Technologies/Technicians and Support Services
                  </option>
                  <option value="Translation">Translation</option>
                  <option value="Transportation">Transportation</option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-6"
                controlId="validationCustom04"
              >
                <Form.Label>Date of Graduation</Form.Label>
                <Form.Control
                  type="date"
                  onChange={handleChange}
                  placeholder="date of graduation"
                  name="graduationYear"
                  value={inputValue.graduationYear}
                  required
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="mb-3 col-md-6" controlId="formTitle">
                <Form.Label>Highest Level of Education</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="higestDegreeLevel"
                  defaultValue={inputValue.higestDegreeLevel}
                  onChange={handleChange}
                >
                  <option> select level</option>
                  <option>Eduction Level</option>
                  <option value="Associate Degree">Associate Degree</option>
                  <option value="Bachelor Degree">Bachelor Degree</option>
                  <option value="Graduate Degree">Graduate Degree</option>
                  <option value="Master Degree">Master Degree</option>
                  <option value="PHD Degree">PHD Degree</option>
                  <option value="Professional Degree">
                    Professional Degree
                  </option>
                  <option value="Undergraduate">Undergraduate</option>
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <div className=" col-6 text-left">
                <Button variant="danger" onClick={handlePrev}>
                  Previous
                </Button>
              </div>
              <div className=" col-6 text-right">
                <Button variant="primary" type="submit">
                  Next
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </Card.Body>
    </Card>
  );
}

export default EducationDetails;
