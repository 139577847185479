import React from "react";

function AboutSection() {
  return (
    <section id="about" className="about section-bg">
      <div className="container" data-aos="fade-up">
        <div className="row no-gutters">
          <div className="content col-xl-5 d-flex align-items-stretch">
            <div className="content">
              <h3>About the Eneconomics Global Solutions</h3>
              <p>
                We are a social impact nonprofit company that specializes in
                youth development (leadership, career, and business), civic
                engagements, and development-driven research. We are an
                indigenous youths-focused solution think-tank company in
                building a sustainable economically empowered Nation
              </p>
              <a href="/about" className="about-btn">
                <span>About us</span> <i className="bx bx-chevron-right" />
              </a>
            </div>
          </div>
          <div className="col-xl-7 d-flex align-items-stretch">
            <div className="icon-boxes d-flex flex-column justify-content-center">
              <div className="row">
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <i className="bx bx-receipt" />
                  <h4>Our Key Area of Focus</h4>
                  <p className="text-justify">
                    Through increased advocacy for a sustainable and
                    economically empowered Nation, we work with the Youth
                    Community (Young Professionals, Influencers,
                    Researchers/Scholars, and Policymakers) and focus on three
                    pillars:{" "}
                  </p>
                  <ul>
                    <li>Development-focused research, </li>
                    <li>Youth Empowerment (leadership and business), and </li>
                    <li>Civic Engagements</li>
                  </ul>
                </div>
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <i className="bx bx-cube-alt" />
                  <h4>Our Purpose</h4>
                  <p>
                    As a social impact company providing development-focused
                    research, youth empowerment, and civic engagement services,
                    we are dedicated to advocating and building a sustainable
                    and economically empowered Nation through the Eneconomics
                    Community.
                  </p>
                </div>
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <i className="bx bx-images" />
                  <h4>OUR VISION</h4>
                  <p>
                    To become a leading global youth hub for developing and
                    leading indigenous solutions, a think-tank for building a
                    sustainable and economically empowered Nation.
                  </p>
                </div>
                <div
                  className="col-md-6 icon-box"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <i className="bx bx-shield" />
                  <h4>OUR MISSION</h4>
                  <p>
                    To engage the Youth Community in brainstorming, innovating,
                    and leading indigenous ideas for a sustainable economically
                    empowered Nation.
                  </p>
                </div>
              </div>
            </div>
            {/* End .content*/}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
