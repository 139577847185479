import React from "react";

function Education({ profile,education }) {
  return (
    <div
      className="tab-pane fade show"
      id="Education"
      role="tabpanel"
      aria-labelledby="Education-tab"
    >
      <ul className="list-unstyled mb-4 mt-3">
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Academic Status:</span>{" "}
          <span>{profile?.education?.academicStatus}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Institution:</span>{" "}
          <span>{profile?.education?.institution}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Specialization:</span>{" "}
          <span>{profile?.education?.specialisation}</span>
        </li>
        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-user" />
          <span className="fw-semibold mx-2">Year of Graduation:</span>{" "}
          <span>{profile?.education?.graduationYear}</span>
        </li>

        <li className="d-flex align-items-center mb-3">
          <i className="bx bx-check" />
          <span className="fw-semibold mx-2">Highst Degree Level:</span>{" "}
          <span>{profile?.education?.higestDegreeLevel}</span>
        </li>
        
      </ul>
    </div>
  );
}

export default Education;
