import React from "react";
import { Modal, Button, Alert, Row, Form } from "react-bootstrap";

function EventDetailModal({
	handleClose,
	show,
	event,
	handlePayment,
	inputValue,
	handleChange,
	handleSubmit,
	setInputValue,
	display,
}) {
	return (
		<Modal
			show={show}
			onHide={handleClose}
			size='md'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>My Event</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{display === "reg-info" ? (
					<>
						<Alert variant={event.status === "pending" ? "info" : "success"}>
							<Alert.Heading>Hello, {event?.clientName}</Alert.Heading>
							{event.status === "pending" && (
								<p>your application is awaiting approval</p>
							)}
							{event.status === "approved" &&
								event.paymentStatus === "pending" && (
									<>
										<p>
											Your application for this event have been approved, you
											can now make payment
										</p>
										<hr />
										<div className='d-flex justify-content-end'>
											<Button
												onClick={() => handlePayment()}
												variant='outline-success'>
												Make Payment
											</Button>
										</div>
									</>
								)}
							{event.paymentStatus === "paid" && (
								<p>You have completed your application for this event</p>
							)}
						</Alert>

						{event.paymentStatus === "paid" && (
							<>
								<ul className='list-unstyled mb-4 mt-3'>
									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-user' />
										<span className='fw-semibold mx-2'>Event Name:</span>{" "}
										<span>{event?.eventName}</span>
									</li>
									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-user' />
										<span className='fw-semibold mx-2'>Shirt Size:</span>{" "}
										<span>{event?.shirtSize}</span>
									</li>
									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-user' />
										<span className='fw-semibold mx-2'>
											Participation Type:
										</span>{" "}
										<span>{event?.participationType}</span>
									</li>
									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-user' />
										<span className='fw-semibold mx-2'>
											Payment Approval:
										</span>{" "}
										<span>
											{event?.status === "pending"
												? "awaiting approval"
												: event?.status === "approved" && "confirmed"}{" "}
										</span>
									</li>
								</ul>
								<small className='text-muted text-uppercase'>
									Emergency Contact
								</small>
								<ul className='list-unstyled mb-4 mt-3'>
									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-phone' />
										<span className='fw-semibold mx-2'>
											Contact Person Name:
										</span>{" "}
										<span>{event?.cpName}</span>
									</li>

									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-envelope' />
										<span className='fw-semibold mx-2'>
											Contact Person Phone:
										</span>{" "}
										<span>{event?.cpPhone}</span>
									</li>
									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-envelope' />
										<span className='fw-semibold mx-2'>
											Contact Person Address:
										</span>{" "}
										<span>{event?.cpAddress}</span>
									</li>
									<li className='d-flex align-items-center mb-3'>
										<i className='bx bx-envelope' />
										<span className='fw-semibold mx-2'>
											Relationship with Contact person:
										</span>{" "}
										<span>{event?.cpRelationship}</span>
									</li>
								</ul>{" "}
							</>
						)}
					</>
				) : (
					<>
						<div className='divider'>
							<div className='divider-text'>
								{" "}
								<p className='card-header'> Complete event Registration</p>
							</div>
						</div>
						<Form onSubmit={handleSubmit}>
							<Row>
								<Form.Group
									controlId='validationCustom01'
									className='mb-3 col-md-6 '>
									<Form.Label>Shirt Size</Form.Label>
									<Form.Select
										name='shirtSize'
										defaultValue={inputValue.shirtSize}
										onChange={handleChange}
										required>
										<option>Select Shirt Size</option>
										<option value='SM'>Small</option>
										<option value='MD'>Medium</option>
										<option value='L'>Large</option>
										<option value='XL'>Extra Large</option>
										<option value='XXL'>Extra Extra Large</option>
									</Form.Select>
								</Form.Group>
							</Row>
							<div className='divider'>
								<div className='divider-text'>
									{" "}
									<h3 className='card-header'> Emergency Contact</h3>
								</div>
							</div>
							<Row>
								<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
									<Form.Label>Full Name</Form.Label>
									<Form.Control
										type='text'
										onChange={handleChange}
										placeholder='enter full name of emergency contact'
										name='cpName'
										value={inputValue.cpName}
										required
									/>
								</Form.Group>
								<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
									<Form.Label>Relationship</Form.Label>
									<Form.Control
										type='text'
										onChange={handleChange}
										placeholder='enter relationship with emergency contact'
										name='cpRelationship'
										value={inputValue.cpRelationship}
										required
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
									<Form.Label>Phone Number</Form.Label>
									<Form.Control
										type='number'
										onChange={handleChange}
										placeholder='enter phone number of emergency contact'
										name='cpPhone'
										value={inputValue.cpPhone}
										required
									/>
								</Form.Group>
								<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
									<Form.Label>Address</Form.Label>
									<Form.Control
										type='text'
										onChange={handleChange}
										placeholder='enter address of emergency contact'
										name='cpAddress'
										value={inputValue.cpAddress}
										required
									/>
								</Form.Group>
							</Row>
							<div className='divider'>
								<div className='divider-text'>
									{" "}
									<h3 className='card-header'> Medical Information</h3>
								</div>
							</div>
							<Row>
								<Form.Group controlId='formdradio' className='col-lg-6 my-3'>
									<Form.Label className='m-2'>
										Do you have a medical condition?{" "}
									</Form.Label>
									<Form.Check
										onChange={(e) =>
											setInputValue({
												...inputValue,
												hasMedCondition: e.target.value,
											})
										}
										type='radio'
										value='yes'
										label='Yes'
										name='hasMedCondition'
										inline
										required></Form.Check>
									<Form.Check
										onChange={(e) =>
											setInputValue({
												...inputValue,
												hasMedCondition: e.target.value,
											})
										}
										type='radio'
										value='no'
										label='No'
										name='hasMedCondition'
										inline
										required></Form.Check>
								</Form.Group>
								{inputValue.hasMedCondition === "yes" && (
									<Form.Group className='mb-3 col-md-6' controlId='formTitle'>
										<Form.Label>Medical condition</Form.Label>
										<Form.Control
											type='text'
											onChange={handleChange}
											placeholder='please state your medical condition'
											name='medicalCondition'
											value={inputValue.medicalCondition}
											required
										/>
									</Form.Group>
								)}
							</Row>
							<Row>
								<div className='d-grid'>
									<Button variant='primary' type='submit'>
										submit
									</Button>
								</div>
							</Row>
						</Form>
					</>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleClose}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default EventDetailModal;
