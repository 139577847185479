import React from "react";
import { Container, Row, Card } from "react-bootstrap";
import PersonalDetails from "./form/PersonalDetails";
import EducationDetails from "./form/EducationDetails";
import WorkDetails from "./form/WorkDetails";
import Preview from "./form/Preview";
import { updateprofile } from "../../store/actions/userProfileActions";
import { clearNotifications } from "../../store/actions/notificationsActions";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

function CompleteBio({ title }) {
  const profile = JSON.parse(localStorage.getItem("profile"))?.result;

  const [inputValue, setInputValue] = React.useState({
    firstname: profile?.name?.split(" ")[1],
    lastname: profile?.name?.split(" ")[0],
    middleName: "",
    email: profile?.email,
    address: profile?.address,
    phone: profile?.phone,
    birthDate: profile?.birthDate,
    gender: profile?.gender,
    userName: profile?.userName,
    nationality: profile?.nationality,
    residentCountry: profile?.residentCountry,
    city: profile?.city,
    postalCode: profile?.postalCode,

    academicStatus: profile?.education?.academicStatus,
    institution: profile?.education?.institution,
    specialisation: profile?.education?.specialisation,
    graduationYear: profile?.education?.graduationYear,
    higestDegreeLevel: profile?.education?.higestDegreeLevel,

    jobTitle: profile?.job?.jobTitle,
    company: profile?.job?.company,
    jobCountry: profile?.job?.jobCountry,

    activities: ["sports", "Economics and Finance"],
    nationalDiscuss: ["security", "health"],
  });

  const [steps, setSteps] = React.useState(1);
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  const renderForm = (
    formstep,
    inputValue,
    handleChange,
    handleNext,
    handlePrev,
    handleSubmit
  ) => {
    switch (formstep) {
      case 1:
        return (
          <PersonalDetails
            inputValue={inputValue}
            handleChange={handleChange}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <EducationDetails
            inputValue={inputValue}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        );
      case 3:
        return (
          <WorkDetails
            inputValue={inputValue}
            handleChange={handleChange}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        );
      case 4:
        return (
          <Preview
            inputValue={inputValue}
            handleSubmit={handleSubmit}
            handlePrev={handlePrev}
          />
        );
      default:
    }
  };

  React.useEffect(() => {
    if (notification?.errors?.message) {
      const { message } = notification?.errors;
      toast.error(message);
    }
    if (notification?.success?.message) {
      const { message } = notification?.success;
      toast.success(message);
    }
    dispatch(clearNotifications());
  }, [dispatch, notification]);

  const handleNext = () => {
    setSteps(steps + 1);
  };
  const handlePrev = () => {
    setSteps(steps - 1);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    setInputValue((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateprofile(inputValue));
  };
  return (
    <Container className="emp-container">
      <div className="text-center p-0 mt-3 mb-2">
        <Card className="px-0 pt-4 pb-0 mt-3 mb-3">
          <h3 className="text-dark">
            <strong>{title}</strong>
          </h3>
          <Row>
            <div className="col-md-12 mx-0">
              <ul id="progressbar">
                <li className={steps === 1 ? "active" : ""} id="personal">
                  {" "}
                  <strong className="m-2">Bio </strong>
                </li>
                <li className={steps === 2 ? "active" : ""} id="education">
                  <strong className="m-2">Education </strong>
                </li>
                <li className={steps === 3 ? "active" : ""} id="work">
                  <strong className="m-2">Work </strong>
                </li>
                <li className={steps === 4 ? "active" : ""} id="confirm">
                  <strong>Finish</strong>
                </li>
              </ul>
            </div>
            <div id="complete-bio-form" className="text-align-left">
              {renderForm(
                steps,
                inputValue,
                handleChange,
                handleNext,
                handlePrev,
                handleSubmit
              )}
            </div>
          </Row>
        </Card>
      </div>
      <ToastContainer position="top-right" />
    </Container>
  );
}

export default CompleteBio;
