import React from "react";
import DashboardContainer from "./DashboardContainer";
import DashboardHeader from "./DashboardHeader";
import { Outlet } from "react-router-dom";

function Dashboard(props) {
  return (
    <>
      <DashboardHeader />
      <DashboardContainer>
        <Outlet />
      </DashboardContainer>
    </>
  );
}

export default Dashboard;
